import React from 'react'
import { Link } from 'react-router-dom'

const NewEventCard = ({
    title = "title name",
    url = "/",
    linkText = "Lihat Selengkapnya",
    date
}) => {
    return (
        <div className='flex flex-col  md:h-56 items-start justify-between gap-4 lg:gap-8 py-4 lg:py-8 border-b-2'>
            <h3 className='text-base lg:text-xl line-clamp-2'>{title}</h3>
            <div className='flex flex-col gap-3'>
                <Link to={url} className='text-sm lg:text-base text-base-gray'>{linkText}</Link>
                {
                    date && (
                        <div className="p-2 bg-[#F26334] text-white text-center rounded-md inline-block">{date}</div>
                    )
                }
            </div>
        </div>
    )
}

export default NewEventCard