import { useRef, useEffect, useState } from "react";
import axios from "axios"
import TextLoader from "components/atoms/Loader/TextLoader"
import { reactLocalStorage } from "reactjs-localstorage"
import { Swiper, SwiperSlide } from "swiper/react";

import { Carousel, Card, Stack, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import SwiperCore, { Pagination, Autoplay, Navigation} from "swiper";
import { setTranslation } from 'redux/global/translationSlice';
import { setBreadcrumb } from 'redux/navigation/breadcrumbSlice';
import { setSidebar } from 'redux/navigation/sidebarSlice';
import parse from 'html-react-parser';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { SuperSEO } from "react-super-seo";
// import required modules

import useSWR from 'swr'
const fetcher = async (url) => {
    const response = await axios({
        method: 'get',
        url: url
    })
    if (response.status === 200) {
        return response.data.footer.banner
    }
}
const Mui = () => {
  const dispatch = useDispatch()
  const [list, setList] = useState([])
  const [dt, setData] = useState([])
  const [banner, setBanner] = useState([])
    const langObj = reactLocalStorage.getObject("gs_lang")
    const lang = langObj.code ? langObj.code : "id"
    const loc = useLocation()
    const slug = loc.pathname.split("/").pop()
    const path = loc.pathname.split("/")
    const { data, isLoading } = useSWR(
      `${process.env.REACT_APP_API}/${lang}/product/${slug}`, 
    fetcher);

   
    const translation = data?.category?.translation
  
    SwiperCore.use([Autoplay, Pagination, Navigation]);


    const FetchList = async () => {
       
      axios.get( `${process.env.REACT_APP_API}/${lang}/product/${slug}`,
      ).then(res => {
   
      const dataList = res.data.product
      setList(dataList)

      setData(res.data)
      setBanner(res.data.banner)
     
      // let pagecount = Math.ceil(res.data.pagination.total / res.data.pagination.limit)
      // setPageCount(pagecount)
      return res;
      })
  }

  useEffect(() => {
    FetchList();
    document.title = isLoading? "GS1 Indonesia" : dt?.name;
      dispatch(setTranslation(translation && Object.values(translation)[0]))
      dispatch(setSidebar(data?.navigation?.sidebar || []))
      dispatch(setBreadcrumb(data?.navigation?.breadcrumb || []))
  }, [data])
    
  SwiperCore.use([Autoplay]);
  

  //console.log("name", dt?.name)
    return (

      <>
       <SuperSEO
        description={dt?.description || ""}
        />
<div className="flex justify-center">
<div className="max-w-[90rem]  right-0 p-2 produk">
 <Swiper 
  //navigation={true}
pagination={true}


loop={true}
modules={[Pagination, Navigation]} 
className="swiperProduct h-[60vh]"

>

     { banner?.map((item,i) => (
        <SwiperSlide>
    
     
       <div className="flex w-6/12">
       <img src={item.image} alt="..." />
       </div>
   
        
                              
 <div
                                className={`layer  absolute left-0 right-0 bottom-0 top-0`}
                                style={{
                                    backgroundImage: `linear-gradient(to right, ${item.background} 50%, transparent)`
                                }}
                            >

<div className="flex row gap-5">
                                <div className="h-full flex flex-col justify-center items-end gap-3 z-100 mt-5">
                                    <h1 className={`text-[#004c95] px-2   text-[32px] w-full md:w-6/12  text-shadow-dark leading-[42px] desc-gallery`}>
                                        {dt?.name}
                                    </h1>
                                     <div className={`px-2  pr-10 z-80 text-[18px] w-full md:w-1/2  leading-[30px] desc-gallery pb-5`}>
                                    
                                     {dt?.overview}
                                     </div>
                                  
                                   
                                </div>
 
                                </div>
                                </div>
                               
                              
         
        </SwiperSlide>
        )) } 

      </Swiper> 

      </div>
    
      </div>
     

        <div className="bg-[#f5f5f5] mt-5">
             
             <div className="page-container">
             <div className='py-6 '>
     <article>
       

{parse(dt?.description || "")}
        </article>
        
      </div>
              </div>
              </div>

        <div className="">
             
            <div className="page-container">
          
                <div className=" my-20 catalog">
         
             <h1 className='text-[#004c95] text-xl font-semibold text-center py-3'>
             {dt?.name}
        </h1>
              <div  className="produk-lain produk-lain-swipper">
                <Swiper
                 
                 slidesPerView={3}
         spaceBetween={30}
         loop={true}
         
         autoplay={{
           delay: 2500,
           disableOnInteraction: true,
         }}
        
        
           navigation={true}
       
         modules={[Autoplay, Pagination, Navigation]}
       
         className="mySwiper"
        
       >
             { list?.map((item,i) => (
        <SwiperSlide>
       
        <Card >
                             <Card.Img variant="top" src={item.image}  className="detail-produk-img"/>
                             <div className="row">
                              <div className="col">
                             
                              </div>
                              </div>
                             <Card.Body >
                             
                             <div className='text-[#004c95]'>
                                {item.name} 
                                </div>
                               <div className="row border-t py-3 mt-3 ">
                                {/* <div className='text-info'>{wordingNew?.monthly_product_before} &#8250;</div> */}
                               <span className='text-[#757575]'>{item.description}</span>
                               </div>
                             </Card.Body>
                           </Card>
                         
          </SwiperSlide>
       
        )) } 

{/* <div
className="relative z-[80] gap-6 left-0 right-0 bottom-20 flex justify-end page-container w-auto ">
<button
    // ref={navigationPrevRef}
   
    className="bg-white cursor-pointer hover:scale-110 duration-300 h-12 w-12 flex items-center justify-center rounded-full shadow">
    <FaArrowLeft className="text-body-primary" />
</button>
<button
    // ref={navigationNextRef}
   
    className="bg-white cursor-pointer hover:scale-110 duration-300 h-12 w-12 flex items-center justify-center rounded-full shadow">
    <FaArrowRight className="text-body-primary" />
</button>
</div> */}
         
       </Swiper> 
     
       </div>


      
                </div>
              
            </div>
        </div>
        <div>
        <div className="page-container">
        <div className="catalog">
        {
              dt.address == false && dt.phone == false && dt.email == false && dt.facebook == false 
              && dt.twitter == false 
              && dt.youtube == false 
              && dt.instagram == false 
              && dt.linkedin == false ?
              "" :
        <h1 className='text-[#004c95] text-xl font-semibold text-center py-3'>
            Contact Us
        </h1>}
        <div className="grid grid-cols-3 gap-4 produk-lain mt-3">
             
             {
              dt.address == false?
              "" :
              <Card >
                            
              <div className="row">
               <div className="col">
              
               </div>
               </div>
              <Card.Body >
               <div className='text-[#004c95] font-bold text-xl'>
               Our Address
               </div>
              <div className='text-[#757575] text-lg'>
                {dt?.address}
               </div>
              
              </Card.Body>
            </Card>
             }
               
             

               {
               dt.phone == false && dt.email == false 
               ?
               ""
               :
               <Card >
                            
                            <div className="row">
                             <div className="col">
                            
                             </div>
                             </div>
                            <Card.Body >
                            <div className="flex row gap-3">
                            {
                              dt?.phone == false ?
                              "" :
                              <div>
                              <div className='text-[#004c95] font-bold text-xl'>
                                Phone
                                </div>
                               <div className='text-primary text-lg'>
                               {dt?.phone}
                                </div>
                                </div>
                            }
                            
                            {
                              dt?.phone == false ?
                              "":
                              <div>
                              <div className='text-[#004c95] font-bold text-xl'>
                              Email
                              </div>
                             <div className='text-primary text-lg'>
                             {dt?.email}
                              </div>
                              </div>
                              }
                            
                              </div>
                            </Card.Body>
                          </Card>
               }
                           
                         
               {
               dt.facebook == false 
               && dt.twitter == false 
               && dt.youtube == false 
               && dt.instagram == false 
               && dt.linkedin == false 
               ? 
               ""
               :
               <Card >
                            
               <div className="row">
                <div className="col">
               
                </div>
                </div>
               <Card.Body >
               
               <div className='text-[#004c95] font-bold text-xl'>
               Our Social Media
                 </div>
                 <div className="flex-1 mb-5 lg-mb-0 col-auto">
       
       <div className='flex gap-3 mt-6'>
        {
          dt?.twitter == false ?
          ""
          :
          <a
          href={dt?.twitter}
         //  href="#"
          target="_blank"
          rel="noopener noreferrer"
          className='h-12 w-12 flex items-center justify-center  rounded-md'
      >
          <img src="/images/sosmed/gs1-symbol-twitter.svg" alt="twitter" />
      </a>
        }
          
        {
          dt?.linkedin == false ?
          "" :
          <a
          href={dt?.linkedin}
        // href="#"
          target="_blank"
          rel="noopener noreferrer"
          className='h-12 w-12 flex items-center justify-center  rounded-md'
      >
          <img src="/images/sosmed/gs1-symbol-linkedin.svg" alt="instagram" />
      </a>
        }
        
        {
         dt?.youtube == false ?
         "" :
         <a
         //href={dataSetting?dataSetting.social_youtube :""}
         href={dt?.youtube}
         target="_blank"
         rel="noopener noreferrer"
         className='h-12 w-12 flex items-center justify-center  rounded-md'
     >
         <img src="/images/sosmed/gs1-symbol-youtube.svg" alt="youtube" />
     </a>
        }
      
          {
            dt?.instagram == false ?
            "":
            <a
            // href={dataSetting?dataSetting.social_instagram :""}
            href={dt?.instagram}
            target="_blank"
            rel="noopener noreferrer"
            className='h-12 w-12 flex items-center justify-center  rounded-md'
        >
            <img src="/images/sosmed/gs1-symbol-instagram.svg" alt="youtube" />
        </a>
          }

       
       </div>
   </div>
               
               </Card.Body>
             </Card>
               }
                        
                         
              </div> 
              </div>
          </div>
        </div>
       
        </>
    )
}

export default Mui