import axios from 'axios';
import PageLoader from 'components/atoms/Loader/PageLoader';
import CardSolution from 'components/molecules/Cards/CardSolution';
import CardBulletin from 'components/molecules/Cards/CardBulletin';
import parse from 'html-react-parser';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { setTranslation } from 'redux/global/translationSlice';
import { setBreadcrumb } from 'redux/navigation/breadcrumbSlice';
import { setSidebar } from 'redux/navigation/sidebarSlice';
import useSWR from 'swr';
import Pagination from 'react-bootstrap/Pagination';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import { SuperSEO } from "react-super-seo";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';


const fetcher = async (url) => {
    const response = await axios({
        method: 'get',
        url: url
    })

    return response.data
}
const MainPage = ({ code }) => {

    const dispatch = useDispatch()
    const breadcrumb = useSelector(state => state.breadcrumb.value)
    const URL = process.env.REACT_APP_API
    const loc = useLocation()
    const lang = reactLocalStorage.getObject("gs_lang").code
    const wording = useSelector(state => state.wording.value)
    const slug = loc.pathname.split("/").pop()
    const { data, isLoading } = useSWR(
        `${URL}/${lang}/page/${slug}` , fetcher)


    const translation = data?.category?.translation

    let active = 2;
    let items = [];
    for (let number = 1; number <= 5; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active}>
                {number}
            </Pagination.Item>,
        );
    }
   
    useEffect(() => {
        document.title = isLoading ? "GS1 Indonesia" : data.name;
        dispatch(setTranslation(lang == "en" ? data&&data?.translation?.id_ID?.slug : data&&data?.translation?.en_US?.slug))
        dispatch(setSidebar(data?.navigation?.sidebar || []))
        dispatch(setBreadcrumb(data?.navigation?.breadcrumb || []))
    }, [data])

    //console.log(data?.description, "ini data")
    var titleData = [];
    var temp = [];
    if (data?.visibility == 2) {
       
        titleData = data?.description.match(/<h(.)>.*?<\/h\1>/g);
        if (titleData != null) {
            var format = titleData[0].substring(0,4)
            var endFormat = titleData[0].substring(titleData[0].length - 5)
          
            var tempData = data?.description.split(format)
            for (let i = 0; i < tempData.length; i++) {
                if (tempData[i] != "") {
                    temp.push(tempData[i].split( endFormat +"\n")[1])
                }            
            }
        }
        

      
    }

    return (
       <>
        <SuperSEO
        description={data?.category?.description}
        />
                    <div>
                         <Breadcrumbs data={breadcrumb} />
                        <div className='mb-12'>
                            <h1 className='text-body-primary text-2xl mb-5'>
                                {data?.name }
                            </h1>
                            {data?.visibility == 2 ?
                                <div>
                                     <div>
                                        {
                                              titleData?.map((item, index) => (
                                                <div class="faq mt-2"> 
                                                    <Accordion>
                                                        <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1-content"
                                                        id="panel1-header"
                                                        color='#f000'
                                                        style={{backgroundColor : '#032C6C', borderRadius : '7px', marginBottom : '5px',  color: 'white'}}
                                                        >
                                                        {parse(item|| "")}
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                        {parse(temp[index]|| "")}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                 </div>
                                                
                                            ))
                                           
                                               
                                        }
     
                                    </div>
                                </div>  :   
                                <div>
                                    <article>
                                        {parse(data?.description || "")}
                                    </article>
                                </div>  
                            } 
                            
                        </div>
                        <div>
                        </div>
                    </div> 
                    </>

    )
}

export default MainPage