import axios from "axios";
import AppLayout from "components/layouts/AppLayout";
import DefaultLayout from "components/layouts/DefaultLayout";
import Home from "pages/Home";
import MainPage from "pages/Main";
import Pages from "pages/Page";
import Bulletin from "pages/Bulletin";
import Content from "pages/Content";
import Contact from "pages/Contact";
import Product from "pages/Product";
import Category from "pages/Category";
import DetailCategory from "pages/Category/Detail";
import Catalog from "pages/Catalog";
import Article from "pages/Article";
import Detail from "pages/Main/Detail";
import Search from "pages/Search";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { reactLocalStorage } from "reactjs-localstorage";
import { setMapping } from "redux/global/mappingSlice";
import { setWording } from "redux/global/wordingSlice";
import 'font-awesome/css/font-awesome.min.css';



import useSWR from "swr";

import DetailCatalog from "pages/Catalog/Detail";
import 'bootstrap/dist/css/bootstrap.css';
import { BsWindowSidebar } from "react-icons/bs";
import SwiperCore, { Pagination, Autoplay, Navigation} from "swiper";

const fetcher = async (url) => {
  const response = await axios({
    method: 'get',
    url: url
  })
  return response.data
}

function App() {
  const location = useLocation()
  const path = location.pathname.split("/")

  const langObj = reactLocalStorage.getObject("gs_lang")
  const rootPath = localStorage.getItem("rootPath")
  !rootPath && reactLocalStorage.setObject("gs_lang", {
    name: 'Bahasa',
    code: 'id',
  })
  
  const lang =  rootPath? rootPath : "id"
  const { data: wording } = useSWR(`${process.env.REACT_APP_API}/wording`, fetcher);
  const { data: mapping, error } = useSWR(`${process.env.REACT_APP_API}/mapping`, fetcher);

  const dispatch = useDispatch()

  const isOpenMenuMobile = useSelector(state => state.mobilemenu)
  useEffect(() => {
    reactLocalStorage.setObject("gs_lang", path[1] == "id"? {
      name: 'Bahasa',
      code: 'id'
    } : 
    {name:"English",
    code:"en"}
    )
    if (isOpenMenuMobile.value) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }
    window.scrollTo({
      top: 0,
    });
  }, [location, isOpenMenuMobile]);
  useEffect(() => {

  
    dispatch(setMapping(mapping && mapping || []))
    dispatch(setWording(wording && wording[lang]))


  }, [dispatch, mapping, wording])


  const pathTrans = location.pathname.split("/")
    const [currentCategory, setCurrentCategory] = useState(pathTrans[1])
    const [currentCategory2, setCurrentCategory2] = useState(pathTrans[2])

  const trans = location.pathname.split("/").pop()
   localStorage.setItem("rootPath", trans !== "en" || trans !== "id"? currentCategory : trans)
  const PagesMain = ({urlSplit}) => {
     var param = useParams();
   
    const pathTrans = location.pathname.split("/")
    const [currentCategory2, setCurrentCategory2] = useState(pathTrans[3])
    const newObjMap = mapping?.en?.filter(obj => {
      return obj.slug == param.first;
    })

    const newObj = newObjMap && newObjMap.map(objnew => {
      return objnew.code
    });
    const newObj2 = newObj? newObj.toString(): "nodata"
// ------------------------------------------------------------------------------
    const newObjMapId = mapping?.id?.filter(obj => {
      return obj.slug == param.first;
    })

    const newObjId = newObjMapId && newObjMapId.map(objnew => {
      return objnew.code
    });

    const newObj2Id = newObjId? newObjId.toString(): "nodata"


// -------------------------------------------------------------------------------

    const newObjMapArt = mapping?.en?.filter(objArt => {
      return objArt.slug == param.slug;
    })

    const newObjArt = newObjMapArt && newObjMapArt.map(objnewArt => {
      return objnewArt.code
    });
    const newObj2Art = newObjArt? newObjArt.toString(): "nodata"

// ---------------------------------------------------------------------------------
// const newObjArticle = mapping?.en?.map(objArt => {
//   return objArt.slug == "global-trade-item-number-gtin-50-years-of-collaboration-innovation-and-leadership";
// })

const newObjArticle = mapping?.en?.filter(objArt => {
  return objArt.slug == trans;
})



const newObjArticleMap = newObjArticle && newObjArticle.map(objnewArt => {
  return objnewArt.code
});

const newObj2Article = newObjArticleMap? newObjArticleMap.toString(): "nodata"

// -----------------------------------------------------------------------------------

const newObjArticleId = mapping?.id?.filter(objArt => {
  return objArt.slug == trans;
})

const newObjArticleMapId = newObjArticleId && newObjArticleId.map(objnewArt => {
  return objnewArt.code
});

const newObj2ArticleId = newObjArticleMapId? newObjArticleMapId.toString(): "nodata"






    
   return(
    newObj2 == "category" || newObj2Id == "category"?
    <Category/>
    : newObj2 == "content" || newObj2Id == "content"?
    <Content/>
    : newObj2 == "catalog" || newObj2Id == "catalog"?
    <Catalog/>
    : newObj2 == "page" || newObj2Id == "page"?
    <Pages/>
    : newObj2 == "bulletin" || newObj2Id == "bulletin"?
    <Bulletin/>
    : newObj2 == "product" || newObj2Id == "product"?
    <Product/>
    : newObj2 == "partner" || newObj2Id == "partner"?
    <MainPage artObj={newObj2Art}/>
    : newObj2 == "contact"  || newObj2Id == "contact"?
    
    <Contact/>
    : pathTrans[2] == "search"?
    
    <Search/>
    : 
    <Detail ObjSlug5new={newObj2Article} ObjSlug5newId={newObj2ArticleId}/>
 
  
   );
    
  }

  const PagesDetail = ({}) => {
    var param = useParams();
  
   const pathDet = location.pathname.split("/")

   const pathDetNew = pathDet[3].toString();

   const newObjMap = mapping?.en?.filter(obj => {
     return obj.slug == param.first;
   })

   const newObj = newObjMap && newObjMap.map(objnew => {
     return objnew.code
   });

   const newObj2 = newObj? newObj.toString(): "nodata"


  //--------------------------------------------------------------  

   const newObjMapArt = mapping?.id?.filter(objArt => {
    return objArt.slug == param.first;
  })

  const newObjArt = newObjMapArt && newObjMapArt.map(objnewArt => {
    return objnewArt.code
  });
  const newObj2Art = newObjArt? newObjArt.toString(): "nodata"


//------------------------------------------------------------------

  const newObjSlug = mapping?.en?.filter(obj => {
    return obj.slug == param.slug;
  })

  const newSlugObj = newObjSlug && newObjSlug.map(objnew => {
    return objnew.code
  });

  const newObjSlugNew = newSlugObj? newSlugObj.toString(): "nodata"

  // -----------------------------------------------------------------

  const newObjSlugInd = mapping?.id?.filter(obj => {
    return obj.slug == param.slug;
  })
  

  const newSlugObjId = newObjSlugInd && newObjSlugInd.map(objnew => {
    return objnew.code
  });

  const newObjSlugNewId = newSlugObjId? newSlugObjId.toString(): "nodata"

  // -------------------------------------------------------------------

  const [currentPartner, setCurrentPartner] = useState(pathDet[2])


   const newObjPartner = mapping?.id?.filter(obj2 => {
    return obj2.slug == currentPartner;
   })

  const newObjPartnerSlug = newObjPartner && newObjPartner.map(objnew2 => {
    return objnew2.code
  });

  const newObjPartnerId = newObjPartnerSlug? newObjPartnerSlug.toString(): "nodata"


  // ----------------------------------------------------------------------------------

  const newObjPartnerEn = mapping?.en?.filter(obj2 => {
    return obj2.slug == currentPartner;
   })

  const newObjPartnerSlugEn = newObjPartnerEn && newObjPartnerEn.map(objnew2 => {
    return objnew2.code
  });

  const newObjPartnerEnNew = newObjPartnerSlugEn? newObjPartnerSlugEn.toString(): "nodata"

  //console.log("newObjPartnerId", newObjPartnerEnNew)

  return(
    newObj2 == "category" || newObj2Art =="category" ?
   <DetailCategory/>
   : newObj == "catalog" || newObj2Art == "catalog"? 
   <DetailCatalog/>
   : newObjSlugNew == "article"
   ||  newObjSlugNew == "partner"
   ||  newObj2 == "article"
   ||  newObj2 == "partner"
   ||  newObj2Art == "article"
   ||  newObj2Art == "partner"
   ||  newObjSlugNewId == "article"
   ||  newObjSlugNewId == "partner"
   ||  newObjPartnerId == "article"
   ||  newObjPartnerId == "partner"
   ||  newObjPartnerEnNew == "article"
   ||  newObjPartnerEnNew == "partner"
   ? 
   <Detail artObj={newObj2Art} artObjId={newObj2} artObjSlug={newObjSlugNew} artObjSlugId={newObjSlugNewId} objLagi={newObjPartnerId} objLagiEn={newObjPartnerEnNew}/>
   :
   <Detail 
   artObj={newObj2Art} 
   artObjId={newObj2} 
   artObjSlug={newObjSlugNew} 
   artObjSlugId={newObjSlugNewId} 
   objLagi={newObjPartnerId} 
   objLagiEn={newObjPartnerEnNew}/>
 
  );
   
 }

  const AppLayoutHandle = ({}) => {
    var param = useParams();
   //console.log(param)
   //console.log("mapping",mapping)

   const newObjMap = mapping?.en?.filter(obj => {
     return obj.slug == param.first;
   })

   const newObj = newObjMap && newObjMap.map(objnew => {
     return objnew.code
   });

   const newObjMapId = mapping?.id?.filter(obj => {
    return obj.slug == param.first;
  })

  const newObjId = newObjMapId && newObjMapId.map(objnew => {
    return objnew.code
  });

  //console.log("newObjId", newObjId)

   //console.log("newObj2",newObj? newObj.toString(): "nodata")


   const newObjArticle = mapping?.en?.filter(objArt => {
    return objArt.slug == trans;
  })
  
  const newObjArticleMap = newObjArticle && newObjArticle.map(objnewArt => {
    return objnewArt.code
  });
  
  const newObj2Article = newObjArticleMap? newObjArticleMap.toString(): "nodata"

  const newObjArticleId = mapping?.id?.filter(objArt => {
    return objArt.slug == trans;
  })
  
  const newObjArticleMapId = newObjArticleId && newObjArticleId.map(objnewArt => {
    return objnewArt.code
  });
  
  const newObj2ArticleId = newObjArticleMapId? newObjArticleMapId.toString(): "nodata"
  //console.log("newObj2Article", newObj2ArticleId)
  return(
  
   <AppLayout obj={newObj} obj2={newObjId} obj3={newObj2Article} newObj2ArticleId ={newObj2ArticleId} path={pathTrans[2]}/>
 
  );
   
 } 
 

 SwiperCore.use([Autoplay]);


  return (
    <>
      <Routes>
        <Route path={lang} element={<DefaultLayout />}>
          <Route index element={<Home />} />
       
          <Route element={<AppLayoutHandle />}>
            <Route path=":first"
              element={<PagesMain urlSplit={currentCategory}/>} 
             />
          
          </Route>
          <Route path=":first/:slug" element={<PagesDetail />} />
         
        </Route>
        <Route  element={<DefaultLayout />}>
        <Route
          path="*"
          element={<Home />}
        />
           </Route>
    
        <Route
          path={`/`}
          element={
          <Navigate to={lang} replace />
          
        }
        />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" />
    </>
  );
}

export default App;
