import { Fragment } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Breadcrumbs = ({
    data
}) => {
    return (
        <nav className='flex items-center py-3 my-3 gap-2'>
            {
                data?.map((item, index) => (
                    <Fragment key={item.slug}>
                        {
                            index > 0 && (
                                <FaChevronRight fontSize={12} className='text-body-primary' />
                            )
                        }
                        {
                            !item.url.includes("javascript") ? (
                                <Link className='text-body-primary' to={item.url}>{item.name}</Link>
                            ) : (
                                <span className='text-body-primary'>{item.name}</span>
                            )
                        }

                    </Fragment>
                ))
            }
        </nav>
    )
}

export default Breadcrumbs