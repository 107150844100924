import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Modal from "components/modal";

const Barcode = ({
  image,
  title = "title here",
  desc,
  url,
  textLink,
  currentId,
}) => {
  return (
    <div className="p-3 hover:shadow-xl hover:scale-[1.01] duration-300 lg:p-6 bg-white border rounded-lg">
      <div className="h-12">
        <h4 className="lg:text-2xl text-body-primary">{title}</h4>
      </div>
      <div className="my-6 lg:my-12">
        <img className="w-full rounded-md" src={image} alt={title} />
      </div>
      <article>
        {currentId === 65 || currentId == 57 ? (
          <>
            {desc && parse(desc.replace(new RegExp("<a [^>]*>(.*?)</a>"), ""))}
            <Modal
              wording={textLink}
              isButton={0}
              url= { currentId == 65 ? "https://www.gs1.org/services/verified-by-gs1" : "https://www.gs1.org/services/verified-by-gs1?gepir_redirect=1&utm_source=gepir_web&utm_medium=gepir_home&utm_campaign=vbg_replaces_gepir"}
              isFooter={0}
            ></Modal>
          </>
        ) : (
          desc && parse(desc)
        )}
      </article>
      {/* <Link to={url} className="text-sm lg:text-base text-body-primary flex items-center gap-3">
                <span>{textLink || "Lihat Selengkapnya"}</span>
                <FaArrowRight />
            </Link> */}
    </div>
  );
};

export default Barcode;
