import Footer from 'components/molecules/Footer'
import Navbar from 'components/molecules/Navbar'
import { Outlet } from 'react-router'

const DefaultLayout = () => {
    return (
        <>
            <Navbar />
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    )
}

export default DefaultLayout