
import React from 'react';

class WordingID extends React.Component {
  render() {
    return  <div className='m-3'>
    <div className='head' >
        <b class="text-xl"><center>Syarat dan ketentuan</center></b>
    </div>
    {/* Restrictions on Data Use. */}
    <b>Pembatasan Penggunaan Data.  </b><br/>
    Penerima Data:
    <ul class=" list-outside list-[upper-alpha]">
        <li>
            Tidak boleh mempersembahkan, mempublikasikan, atau menggunakan Data dengan cara yang salah atau menyesatkan; melanggar hak-hak pihak ketiga (termasuk hak Penyedia Data atau Pihak Yang Ditunjuk) dan/atau melanggar hukum dan peraturan yang berlaku;
        </li>
        <li>
            Tidak akan mengubah isi Data dalam mempublikasikan atau menyebarluaskan Data tersebut (kecuali jika memiliki persetujuan tertulis dari Penyedia Data atau Pihak Yang Ditunjuk untuk melakukannya);
        </li>
        <li>
            Tidak akan mempublikasikan atau menggunakan Data dengan cara yang menyiratkan dukungan apa pun dari Penyedia Data, Pihak Yang Ditunjuk, atau GS1 (kecuali memiliki izin tertulis dari Penyedia Data, Penerima atau GS1 untuk melakukannya);
        </li>
        <li>
            Hanya akan menggunakan Data sesuai dengan Penggunaan yang Diizinkan yang berlaku untuk Solusi [lihat "Penggunaan yang Diizinkan atas Solusi" di bawah ini]; dan
        </li>
        <li>
        Tidak boleh mendekompilasi, merekayasa balik, mengubah, atau dengan cara apa pun merusak (atau mencoba melakukannya) dengan semua atau sebagian dari [solusi Mos] atau perangkat lunak atau solusi apa pun yang terkandung di dalamnya atau yang terhubung dengannya, atau menyebabkan, mengizinkan atau membantu orang lain secara langsung atau tidak langsung untuk secara langsung atau tidak langsung melakukan salah satu hal di atas. Klausul [a) - e)] di atas akan tetap berlaku setelah pengakhiran akses ke Solusi."
        </li>
    </ul>
    <hr />
    {/* Permitted Use for the Solution.  */}
    <b>Penggunaan yang Diizinkan untuk Solusi. </b><br/>
    Contoh: “Data yang disediakan untuk Penerima Data melalui Solusi Verified by GS1 (“VbG”) harus tunduk pada ketentuan penggunaan berikut (secara bersama-sama disebut “Penggunaan yang Diizinkan”):
    <ul class=" list-outside list-[upper-alpha]">
        <li>
        [Penawaran Solusi melalui API] Penerima Data tidak boleh menggunakan, menjual, mensublisensikan, mendistribusikan, atau menyediakan Data kepada pihak ketiga, kecuali penggunaan tersebut merupakan bagian dari Produk Nilai Tambah. Penerima Data juga harus memastikan bahwa pihak ketiga mana pun (termasuk, namun tidak terbatas pada, penyedia solusi, agen, anak perusahaan, atau subkontraktor) juga terikat untuk mematuhi batasan penggunaan yang ditetapkan dalam bagian [Penggunaan yang Diizinkan] dan bagian [Pembatasan Penggunaan]. GS1 [NAMA MO] berhak untuk segera menghapus akses API Penerima Data jika Penerima Data dicurigai atau ditemukan melanggar ketentuan bagian [#] ini. Untuk tujuan bagian ini, "Produk Bernilai Tambah" berarti produk atau layanan yang ditawarkan oleh Penerima Data kepada pengguna akhir yang menggunakan Data dan menambahkan nilai yang berarti padanya, termasuk, tanpa batasan, dengan menggabungkannya dengan data lain, informasi atau analisis yang bersumber atau dikembangkan oleh Penerima Data atau dengan mengolah atau menyajikan Data dengan cara baru. Replikasi Data dan/atau Layanan tidak akan dianggap sebagai penambahan nilai yang berarti; dan
        </li>
        <li>
        [untuk Tawaran Solusi Lain] Penerima Data dapat menggunakan Data semata-mata dalam bisnisnya dan untuk proses bisnisnya sendiri, tidak termasuk penggunaan komersial apa pun ('penggunaan komersial' berarti penggunaan apa pun di mana Data dijual, disewakan, dilisensikan, atau disediakan secara keseluruhan atau sebagian, sendiri atau sebagai bagian dari produk/layanan lain).
Penerima Data tidak boleh membagikan, merilis, mengirimkan, atau mengizinkan ekstraksi Data oleh pihak mana pun selain karyawan atau agennya sendiri.
        </li>
    </ul>
    <hr />
    {/* Inactive Licence.  */}
    <b>Lisensi yang Tidak Aktif.  </b><br/>
    Jika Penerima Data menemukan GTIN yang terkait dengan Lisensi Tidak Aktif,
    <ul class=" list-outside list-[lower-roman]">
        <li>Penerima Data harus membantu GS1 dengan memberi tahu GS1 mengenai GTIN tersebut; dan</li>
        <li>jika Penerima Data adalah organisasi penjualan, GS1 menyarankan agar organisasi tersebut juga memberitahukan kepada pemasoknya tentang Lisensi Tidak Aktif tersebut sesuai dengan Solusi bagi pemasok untuk memberitahukan MO masing-masing. Untuk tujuan bagian ini, "Lisensi Tidak Aktif" berarti lisensi kunci identifikasi GS1 yang telah kedaluwarsa atau dihentikan berdasarkan syarat  dan ketentuan yang berlaku.</li>
    </ul>
    <hr />
    {/* Penangguhan & Penghentian.  */}
    <b>Penangguhan & Penghentian.  </b><br/>
    GS1 dapat menangguhkan akses Penerima Data ke dan penggunaan Data apa pun yang berasal dari Solusi dengan segera berlaku jika Penerima Data (atau Pengguna Penerima Data yang Sah) melakukan pelanggaran material terhadap ketentuan mana pun dari Ketentuan Penggunaan ini dan sampai pelanggaran tersebut diperbaiki, atau untuk mencegah kerugian yang akan segera terjadi pada pihak ketiga. Jika pelanggaran tetap tidak dapat diperbaiki untuk [misalkan lima belas (15)] hari berturut-turut, GS1 berhak menghentikan akses Penerima Data ke dan penggunaan Data apa pun yang berasal dari Solusi dengan segera. Ketentuan Penggunaan ini akan tetap berlaku setelah penghentian akses Penerima Data ke Solusi(-Solusi) Data Keluar.


    {/* Disclaimer of Warranties.  */}
    <b>Penafian Jaminan. </b><br/>
    Contoh: “Solusi(-solusi) menampilkan Data yang tidak dimiliki atau dikendalikan oleh GS1 AISBL, GS1 Indonesia, atau Organisasi Anggota GS1, oleh karena itu Data disediakan atas dasar 'sebagaimana adanya' dan 'sebagaimana tersedia'. Verifikasi yang tersedia dari Solusi(-solusi) terbatas pada pemeriksaan logis otomatis tertentu dan tidak mencakup validasi fisik atau legal atas keakuratan Data. Baik GS1 maupun Organisasi Anggota GS1, Penyedia Data, atau Pihak Yang Ditunjuk tidak menyatakan atau menjamin bahwa Data tersebut akurat, lengkap, dan/atau terkini. Penggunaan Data, dan Solusi(-solusi) adalah satu-satunya risiko Penerima Data. GS1 tidak menyatakan atau menjamin bahwa Solusi(-solusi) akan aman atau bebas dari kesalahan atau gangguan. Baik GS1 AISBL maupun GS1 Indonesia tidak menyatakan atau menjamin bahwa Solusi dan Data cocok untuk tujuan regulasi apa pun, termasuk namun tidak terbatas pada persyaratan pelaporan regulasi apa pun di sektor perawatan kesehatan. Sejauh diizinkan oleh undang-undang, GS1 tidak membuat pernyataan atau jaminan, tersurat, tersirat atau lainnya, terkait masalah apa pun, termasuk Data, dan Solusi(-solusi). Representasi atau jaminan semacam itu secara tegas dinafikan.
    <hr />
    {/* Limitation of Liability.  */}
    <b>Batasan Tanggung Jawab.  </b><br/>
    Sejauh diizinkan oleh perundang-undangan dan aturan hukum, GS1 AISBL, GS1 Indonesia, Organisasi Anggota GS1, Penyedia Data dan/atau Penerima Kuasa tidak akan bertanggung jawab atas segala kerugian apa pun, termasuk namun tidak terbatas pada kerugian yang aktual, langsung, konsekuensial, tidak langsung, tidak disengaja, atau kerugian yang bersifat menghukum, apakah tanggung jawab tersebut didasarkan pada pelanggaran kontrak, pelanggaran jaminan atau sebaliknya, bahkan jika diberitahukan tentang kemungkinan kerugian tersebut, yang mungkin timbul dari penggunaan Penerima Data atas Solusi dan/atau Data atau untuk setiap kegagalan atau penolakan oleh GS1 untuk memberikan akses kepada Penerima Data ke Solusi dan/atau Data, atau kerugian, dampak, atau kerusakan yang diderita oleh Penerima Data sebagai akibatnya.
     <hr />
    {/* Indemnity.  */}
    <b>Ganti rugi. </b><br/>
    Penerima Data harus sepenuhnya mengganti rugi, membebaskan dan membela GS1 AISBL, GS1 Indonesia, dan Organisasi Anggota GS1 dari dan terhadap semua klaim, tindakan, kerusakan, kewajiban, tanggung jawab, kerugian, penyelesaian, penilaian, dan biaya (termasuk biaya kuasa hukum yang wajar), yang diajukan oleh pihak ketiga mana pun yang timbul dari, terkait dengan atau akibat dari penggunaan apa pun oleh Penerima Data atas Data, Solusi(-solusi) atau Registry Platform GS1 yang melanggar Ketentuan Penggunaan ini atau hukum atau peraturan yang berlaku.
</div>;
  }
}

export default WordingID;