import { Fragment, useState, useEffect } from 'react'
import { BsFillCaretDownFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { reactLocalStorage } from 'reactjs-localstorage'
import { setMapping } from "redux/global/mappingSlice";
import useSWR from "swr";
import axios from "axios";
const options = [
    {
        name: 'Bahasa',
        code: "id"
    },
    {
        name: 'English',
        code: 'en',
    },
]

const DropdownLang = () => {
    const fetcher = async (url) => {
        const response = await axios({
          method: 'get',
          url: url
        })
        return response.data
      }
      const dispatch = useDispatch()
    const location = useLocation()
    const pathNext = location.pathname.split("/")[1]
    const pathSlug = location.pathname.split("/")
    const lang = reactLocalStorage.getObject("gs_lang")
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    // const [selected, setSelected] = useState(lang ? lang : { name: "Bahasa", code: "id" })
    const transLocal = localStorage.getItem("rootPath")
     const newTrans =  transLocal == "id"? { name: "Bahasa", code: "id" } : "en"? { name: "English", code: "en" } :"" 
     const [selected, setSelected] = useState({ name: "Bahasa", code: "id" })
     const { data: mapping2, error } = useSWR(`${process.env.REACT_APP_API}/mapping`, fetcher);
    const translation = useSelector(state => 
        state.translation.value
        )

        const path = location.pathname.split("/").pop()
        const pathTrans = localStorage.getItem("rootPath")
        //console.log("path", path)
        //console.log("pathSlug", pathSlug)

        //console.log("lang", lang.code)
        

        const handleLocal =  () => {
            if (lang.code == "id" ) {
               // reactLocalStorage.setObject("gs_lang", { name: "Bahasa", code: "id" } )
               setSelected({ name: "Bahasa", code: "id" })
            } if (lang.code == "en" ){
               // reactLocalStorage.setObject("gs_lang", { name: "English", code: "en" } )
               setSelected({ name: "English", code: "en" })
            }else{
                // reactLocalStorage.setObject("gs_lang", { name: "English", code: "en" } )
                setSelected({ name: "Bahasa", code: "id" })
             }
        }
   
       useEffect(() => {
            handleLocal()
          
            dispatch(setMapping(mapping2 && mapping2 || []))
         }, [dispatch, mapping2])
   
         //console.log("mapping dropdown", mapping2)
   
         
   
       //   const newMappIdTrue = newMappId?.filter((objNew, i) => {
       //     return objNew.i == true;
       //   })
      

        //  //console.log("mapping2 pathSlug.length", mapping2.id[49].slug)

        
       
    //    //console.log("newMappEn map", newMappEn)
       
    //    //console.log("newMappId map", newMappId)
    //    //console.log("newMappInIndex indexmap", newMapInIndex)
    //    //console.log("newMapEnIndex indexmap", newMapEnIndex)
       
      
         
       
    const changeLanguange = async (data) => {

        const newMappEn = mapping2?.en.findIndex(objArt => {
            return objArt.slug == path;
          })
          const newMappId = mapping2?.id.findIndex(objArt => {
            return objArt.slug == path;
          })
        //   //console.log("newMappEn indexNum", newMappEn)
        //   //console.log("newMappId indexNum", newMappId)
        //   //console.log("newMappId inIndexNum",  mapping2.en[newMappEn].slug)
    //     const newMapInIndex = mapping2.id[newMappId].slug?mapping2.id[newMappId].slug : ''
        //const newMapEnIndex = mapping2.en[newMappEn].slug?mapping2.en[newMappEn].slug : ''
        const newMapInIndex = mapping2.id[newMappEn] ? mapping2.id[newMappEn].slug :''
        const newMapEnIndex = mapping2.en[newMappId]? mapping2.en[newMappId].slug :''
        const newMapIdIndexToString = newMapInIndex.toString()
        const newMapEnIndexToString = newMapEnIndex.toString()
        // //console.log("newMapEnIndexToString tostring", newMapEnIndexToString)
        // //console.log("newMapIdIndexToString tostring", newMapIdIndexToString)


        setSelected(data)
        reactLocalStorage.setObject("gs_lang", data)
        reactLocalStorage.setObject("rootPath", pathNext !== "en" || pathNext !== "id" ? pathSlug : pathNext)
        setOpen(false)
       //console.log("data", data.code)
        const langTo = lang.code === "id" ? "/en" : "/id"
     
        //  navigate(path !== "en" || path !== "id"? "/" + data.code + "/" + path :"/" + data.code)
        if(path == "en" || path == "id") {
            navigate("/" + data.code)
        } else  if(pathSlug.length == 3 && data.code == "en") {
            navigate("/" + data.code + "/" + newMapEnIndexToString)
        }else  if(pathSlug.length == 3 && data.code == "id") {
            navigate("/" + data.code + "/" + newMapIdIndexToString)
        }
        else  if(pathSlug.length >= 3 && data.code == "en") {
            navigate("/" + data.code + "/" + pathSlug[2] + "/" + newMapEnIndexToString)
        }else  if(pathSlug.length >= 3 && data.code == "id") {
            navigate("/" + data.code + "/" + pathSlug[2] + "/" + newMapIdIndexToString)
        }
     
        // navigate(0)
        window.location.reload();

    }
      //console.log("selected", selected)

    
    //   //console.log("newMappIdTrue map", newMappIdTrue)

    return (
        <div className='relative'>
            <button
                onClick={() => setOpen(!open)}
                className='flex w-24 p-2.5 rounded-md items-center bg-[#2A5985] text-white gap-3'>
                <span>{selected.name}</span>
                <BsFillCaretDownFill />
            </button>
            {
                open && (
                    <div className='absolute top-12 py-1 text-center left-0 right-0 bg-white rounded-md shadow'>
                        {
                            options.map(item => {
                                return (
                                    <Fragment key={item.code} >
                                        {
                                            selected.code !== item.code && (
                                                <button
                                                    className='p-2'
                                                    onClick={() => changeLanguange(item)}
                                                >
                                                    {item.name}
                                                </button>
                                            )
                                        }
                                    </Fragment>
                                )
                            })
                        }

                    </div>
                )
            }

        </div>
    )
}

export default DropdownLang