import NewEventCard from 'components/molecules/Cards/NewEventCard'

const NewsEvent = ({
    data, wording
}) => {
    return (
        <div className='py-10 lg:py-18'>
            <div className="page-container grid md:grid-cols-2 gap-12">
                {
                    data?.map((item, index) => (
                        <div key={index}>
                            <h1 className="text-body-primary font-semibold text-[24px]">{item.header} </h1>
                            {
                                item?.list?.map(l => (
                                    <NewEventCard
                                        key={l.id}
                                        title={l.name}
                                        url={l.slug}
                                        linkText={wording?.btn_more}
                                        date={l.start}
                                    />
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default NewsEvent