import React, { Fragment, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import PageLoader from "components/atoms/Loader/PageLoader";
import { useLocation } from "react-router-dom";
import Modal from "components/modal";
const Sidebar = ({
  data,
  ObjSlug,
  ObjSlug2,
  ObjSlug3,
  ObjSlug4,
  objLagi,
  objLagiEn,
  ObjSlug5,
  ObjSlug5newId,
  isLoading,
  data2,
}) => {

  const location = useLocation();
  const path = location.pathname.split("/");
  const [currentSlug, setCurrentSlug] = useState(path[2]);
  const langObj = reactLocalStorage.getObject("gs_lang");
  const lang = langObj.code ? langObj.code : "en";

  return (
    <div className="mt-8">
      {ObjSlug == "article" ||
      ObjSlug2 == "article" ||
      ObjSlug3 == "article" ||
      ObjSlug4 == "article" ||
      ObjSlug == "partner" ||
      ObjSlug2 == "partner" ||
      objLagi == "partner" ||
      objLagiEn == "partner" ||
      ObjSlug5 == "article" ||
      ObjSlug5newId == "article" ? (
        <>
          {data2 &&
            data2.map((item, index) => {
              return (
                <Fragment key={item.id}>
                  <Link
                    className=" border-b-2 block"
                    to={`/${lang}/${currentSlug}/${item?.slug}`}
                  >
                    <div className="text-body-primary py-2">{item.name} </div>
                  </Link>
                </Fragment>
              );
            })}
          {/* <p>asas</p> */}
        </>
      ) : (
        <>
          <h1 className="text-body-primary font-semibold text-xl mb-6">
            {data ? data.name : ""}
          </h1>
          {/* <h2 className='text-body-primary font-semibold text-xl mb-6'>asasa</h2>  */}
          <div>
            {data?.children?.map((item, index) => {
              return (
                <Fragment key={item.id}>
                  {item.children ? (
                    <Disclosure>
                      {({ open }) => (
                        <div className="py-1 border-t-2">
                          <Disclosure.Button className="flex w-full text-left pb-1">
                          <a
                                  className="py-1 border-t-2 block"
                                  href={
                                    item.url.substring(0, 5) == "https"
                                      ? `${item.url}`
                                      : `/${lang}${item.url}`
                                  }
                                  target={
                                    item.url.substring(0, 5) == "https"
                                      ? "_blank"
                                      : ""
                                  }
                                >
                              <span className="text-body-primary">
                                {item?.name}
                              </span>
                            </a>
                          </Disclosure.Button>
                          {item?.children?.map((item) => (
                            <div className=" pl-5">
                              {item.url.substring(0, 5) == ""}
                              {item.id === 27 || item.id == 28 ? (
                                <Modal
                                  wording={item.name}
                                  isButton={0}
                                  url={
                                    item.id == 27 ? "https://www.gs1.org/services/verified-by-gs1" : "https://www.gs1.org/services/verified-by-gs1?gepir_redirect=1&utm_source=gepir_web&utm_medium=gepir_home&utm_campaign=vbg_replaces_gepir"
                                  }
                                  isFooter={1}
                                ></Modal>
                              ) : (
                                <a
                                  className="py-1 border-t-2 block"
                                  href={
                                    item.url.substring(0, 5) == "https"
                                      ? `${item.url}`
                                      : `/${lang}${item.url}`
                                  }
                                  target={
                                    item.url.substring(0, 5) == "https"
                                      ? "_blank"
                                      : ""
                                  }
                                >
                                  <span className="text-body-primary">
                                    {item?.name}
                                  </span>
                                </a>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </Disclosure>
                  ) : (
                    <a
                      className="py-1 border-t-2 block"
                      href={
                        item.url.substring(0, 5) == "https"
                          ? `${item?.url}`
                          : `/${lang}${item?.url}`
                      }
                    >
                      <span className="text-body-primary">{item?.name} </span>
                    </a>
                  )}
                </Fragment>
              );
            })}
            {/* <Disclosure >
                    {({ open }) => (
                        <div className='py-3 border-t-2'>
                            <Disclosure.Button className="flex w-full text-left">
                                <span className='text-body-primary'>Provider Partners</span>
                            </Disclosure.Button>
                            <Disclosure.Panel>
                                <div className='text-body-primary m-3'>MENU CHILD 1 HERE</div>
                                <div className='text-body-primary m-3'>MENU CHILD 1 HERE</div>
                                <div className='text-body-primary m-3'>MENU CHILD 1 HERE</div>
                            </Disclosure.Panel>
                        </div>
                    )}
                </Disclosure> */}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
