import { createSlice } from '@reduxjs/toolkit'
import { reactLocalStorage } from 'reactjs-localstorage'
const initialState = {
    value: {},
}


export const translationSlice = createSlice({
    name: 'translation',
    initialState,
    reducers: {
        setTranslation: (state, action) => {
            state.value = action.payload
            // reactLocalStorage.setObject("translation", action.payload)
        },
    },
})

export const { setTranslation } = translationSlice.actions

export default translationSlice.reducer