// import axios from "axios"
import axios from "axios"
import PageLoader from "components/atoms/Loader/PageLoader"
import SectionBanner from "components/organisms/HomePage/SectionBanner"
import Dedication from "components/organisms/HomePage/SectionContent/Dedication"
import Mui from "components/organisms/HomePage/SectionContent/Mui"
import NewsEvent from "components/organisms/HomePage/SectionContent/NewsEvent"
import Registration from "components/organisms/HomePage/SectionContent/Registration"
import Services from "components/organisms/HomePage/SectionContent/Services"
import SolutionProvider from "components/organisms/HomePage/SectionContent/SolutionProvider"
import { useSelector } from "react-redux"
import { useEffect, useState } from 'react';
import { reactLocalStorage } from "reactjs-localstorage"
import useSWR from 'swr'
import { useLocation } from "react-router-dom"; 
import { SuperSEO } from "react-super-seo";

const fetcher = async (url) => {
    const response = await axios({
        method: 'get',
        url: url
    })
    return response.data
}

const Home = () => {
    
    const langObj = reactLocalStorage.getObject("gs_lang")
    !langObj.code && reactLocalStorage.setObject("gs_lang", {
        name: 'English',
        code: 'en',
    })
    const lang = langObj.code ? langObj.code : "en"
    const langTrans = localStorage.getItem("rootPath")
    //console.log("langTrans", langTrans)
    const { data: datawording } = useSWR(`${process.env.REACT_APP_API}/wording`, fetcher);
    const { data, error, isLoading } = useSWR(`${process.env.REACT_APP_API}/${lang}/home-v2`, fetcher);
    
    const wording = datawording && datawording[lang]
    // const location = useLocation();
    // const trans = location.pathname.split("/")
    // const [newTrans, setNewTrans] = useState(trans[1])
    // localStorage.setItem("rootPath", newTrans)

    // //console.log("wording", wording.event_more)
    useEffect(() => {
        document.title = "Home - GS1 Indonesia";
       
    }, [])
    return (
        <>
          <SuperSEO
        description="Home - GS1 Indonesia"
        />
            {
                isLoading && <PageLoader />
            }
            <SectionBanner
                error={error}
                data={data?.section_1}
                wording={wording}
            />
            <div
                style={{
                    backgroundImage: `linear-gradient(#FFFFFF,#E9F4FB)`
                }}
            >

                <Dedication
                    data={data?.section_2}
                />
                <Services
                    error={error}
                    data={data?.section_3}
                    wording={wording}
                />
            </div>
            <div
                style={{
                    
                    backgroundColor: `linear-gradient(180deg, #FAFAFA 65.17%, #E3F1FA 143.87%)`
                }}
            >
                <Registration
                    error={error}
                    data={data?.section_4}
                    wording={wording}
                />
                <SolutionProvider
                    error={error}
                    data={data?.section_5}
                    wording={wording}
                />
                <NewsEvent
                    data={data?.section_6}
                    wording={wording}
                />
                </div>
                <div
                style={{
                    
                    backgroundColor: `#F4F4F4`
                }}
            >
                <Mui />
                </div>

            
        </>
    )
}

export default Home