import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { reactLocalStorage } from "reactjs-localstorage"
const lang = reactLocalStorage.getObject("gs_lang").code
export const getDataHome = createAsyncThunk("menutop/getDataHome", async () => {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}/${lang}/home-v2`
    })
    return response.data
})

const homeEntity = createEntityAdapter({
    // selectId: (home) => home.section_1
})

const homeSlice = createSlice({
    name: "home",
    initialState: homeEntity.getInitialState(),
    extraReducers: {
        [getDataHome.pending]: (state, action) => {
            state.isLoading = true
        },
        [getDataHome.fulfilled]: (state, action) => {
            homeEntity.setAll(state, action.payload)
            state.isLoading = false
        },
    }
})

export const homeSelectors = homeEntity.getSelectors(state => state.home)

export default homeSlice.reducer