import axios from 'axios';
import PageLoader from 'components/atoms/Loader/PageLoader';
import CardSolution from 'components/molecules/Cards/CardSolution';
import CardBulletin from 'components/molecules/Cards/CardBulletin';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { setTranslation } from 'redux/global/translationSlice';
import { setBreadcrumb } from 'redux/navigation/breadcrumbSlice';
import { setSidebar } from 'redux/navigation/sidebarSlice';
import useSWR from 'swr';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Pagination from '@mui/material/Pagination';
import { SuperSEO } from "react-super-seo";
import { setWording } from "redux/global/wordingSlice";

const fetcher = async (url) => {
    const response = await axios({
        method: 'get',
        url: url
    })
    return response.data
}
const Detail = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const breadcrumb = useSelector(state => state.breadcrumb.value)
    const path = location.pathname.split("/")
    const slug = location.pathname.split("/").pop()
    const [currentSlug, setCurrentSlug] = useState(path[3])
    const [currentCategory, setCurrentCategory] = useState(path[2])
    const [list, setList] = useState([])
    const [pageCount, setPageCount] = useState()
    const [currentType, setCurrentType] = useState("article")
    const lang = reactLocalStorage.getObject("gs_lang").code
    const rootPath = reactLocalStorage.getObject("rootPath")
    const { data: wording } = useSWR(`${process.env.REACT_APP_API}/wording`, fetcher);

    const { data, error, isLoading } = useSWR(`${process.env.REACT_APP_API}/${lang}/catalog/${currentCategory}/${currentSlug}`, fetcher);
   
    //  const translation = data && Object.values(data?.category?.translation)
   
    const FetchList = async () => {
       
      axios.get( `${process.env.REACT_APP_API}/${lang}/catalog/${currentCategory}/${currentSlug}`,
      ).then(res => {
   
      const dataList = res.data.list
      setList(dataList)
     
      let pagecount = Math.ceil(res.data.pagination.total / res.data.pagination.limit)
      setPageCount(pagecount)
      return res;
      })
  }

  const handlePage = async (val) => {
       
    axios.get( `${process.env.REACT_APP_API}/${lang}/catalog/${currentCategory}/${currentSlug}/page/${val}`,
    ).then(res => {
 
    const dataList = res.data.list
    setList(dataList)
   
    let pagecount = Math.ceil(res.data.pagination.total / res.data.pagination.limit)
    setPageCount(pagecount)
    return res;
    })
}

  //console.log("list", list)
   
    useEffect(() => {
      //  dispatch(setTranslation({
      //       id: translation?.id,
      //       name: translation?.name,
      //       slug: rootPath + "/" + translation?.slug,
      //   }))
      document.title = isLoading? "GS1 Indonesia" : data?.catalog?.name;
      FetchList();
      dispatch(setWording(wording && wording[lang]))
        dispatch(setSidebar(data?.navigation?.sidebar || []))
        dispatch(setBreadcrumb(data?.navigation?.breadcrumb || []))
    }, [dispatch, data, ])

    const wordingNew = wording && wording[lang]

    return (
      <>
    <SuperSEO
        description={data?.catalog.description || ""}
        />
        <div className="container">
        <div className='pb-5 catalog'>
          <Breadcrumbs data={breadcrumb} />
           {/* { data?.navigation?.tab?.map((item,i) => ( 
              
            )) } */}
              <div className='mb-12'>
              <h1 className='text-body-primary text-2xl mb-5'>
                    {data?.catalog?.name} 
                </h1>

            

                <div className="row">
                    <div className="col col-auto">
                    
                           
                   
                               <Card >
                              
                             <Card.Img variant="top" src={data?.list[0].image} className="produk-img" />
                             <div className="row">
                              <div className="col">
                              
                              </div>
                              </div>
                             <div className="row pl-3">
                              <div className="col">
                              <Badge id='product-name'>
                                {data?.list[0].name}
                              </Badge>
                              </div>
                              </div>
                             <Card.Body className='text-white'>
                             
                             
                                {data?.list[0].overview} 
                            
                               <div className="row border-t py-3 mt-3">
                               <Link to={`/${lang}/${data?.list[0].slug}`}>
                               <div> {wordingNew?.btn_detail} &#8250;</div>
                               </Link>
                               </div>
                             </Card.Body>
                            
                           </Card>
                    


                     
                    </div>
                    <div className="col">
                        <div className="row">
                    {parse(data?.catalog?.description || "")}
                    </div>
                    <div className="row">
                     <div className=' items-center border-r border-l border-b border-t-4 border-t-[#ee7442] group p-3 mt-5'>
                        <div className="row">
                        <p className='text-center'>{data?.catalog?.overview}</p>
                        </div>
                        <div className="row mt-3">
                            <div className="col flex justify-content-center items-center">
                            <button className='rounded-md flex gap-3 items-center group bg-[#F26334]  py-2.5 px-5 font-semibold'>
                                Publication@gs1id.org
                            </button>
                            </div>
                        </div>
                    </div>
            </div>
                    </div>
                    </div>
              </div>
              <div className="row">
                <h2 className='text-body-primary text-2xl mb-5'>
                Produk-Produk Lainnya gg
                </h2>
                </div>
              <div className="grid grid-cols-3 gap-4 produk-lain">
              { list?.map((item,i) => (
                i !== 0 ?
              <Card >
                             <Card.Img variant="top" src={item.image}  className="produk-img"/>
                             <div className="row">
                              <div className="col">
                             
                              </div>
                              </div>
                             <Card.Body >
                             
                             <div className='text-[#004c95]'>
                                {item.name} 
                                </div>
                               <div className="row border-t py-3 mt-3">
                               <Link to={`/${lang}/${data?.list[0].slug}`}>
                               <div className='text-info'> {wordingNew?.btn_detail} &#8250;</div>
                               </Link>
                               </div>
                             </Card.Body>
                           </Card>
                           :""
                           )) } 
              </div>

              <div className="flex flex-row justify-center mt-5">
            <Pagination 
                            count={pageCount} 
                            shape="rounded" 
                          
                            onChange={(e, value) => handlePage(value) }
                            /> 
            </div>

              <div className=' items-center border-r border-l border-b border-t-4 border-t-[#ee7442] group p-3 mt-5'>
                <div className="row py-2 px-4 border-b text-[#004c95] ">
                Archive
                </div>
                <div className="row px-4 pt-3">
                    <div className="d-flex col gap-3">
                    {
                    data?.archive?.map((arc,i) => (  
                        <Link to={`/${lang}/${currentCategory}/${arc.year}`}>
                    <div className="d-flex col gap-3 text-info">
                        <div>{arc.year}</div> 
                        <div>&#8250;</div>
                    </div>
                  </Link>
                    ))}
                    </div>
                   
                </div>
            </div>
    
      </div>
      </div>
      </>
    )
}

export default Detail