import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    value: [],
}


export const mappingSlice = createSlice({
    name: 'mapping',
    initialState,
    reducers: {
        setMapping: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setMapping } = mappingSlice.actions

export default mappingSlice.reducer