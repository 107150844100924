import Button from 'components/atoms/Button';
import parse from 'html-react-parser';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const SolutionProvider = ({ data, wording }) => {
    const navigate = useNavigate()
    return (
        <div className='py-10 lg:py-18 my-[80px] lg:my-[133px]'>
            <div className="page-container md:flex p-0 border-b-2">
                <div className='flex-1 items-center pb-3 lg:pb-5'>
                    <h3 className='text-[30px] lg:text-[40px] text-body-primary'>
                        {data?.content?.name}
                    </h3>
                    <div className='text-[16px] lg:text-[18px] text-base-gray my-4 lg:my-8'>
                        {data && parse(data?.content?.description)}
                    </div>
                    {/* <Button
                        onClick={() => navigate('/solution/' + data?.content?.slug)}
                        varian='orange'
                        outline

                        label={wording?.btn_more}
                        icon={<FaArrowRight />}
                    />  */}


                </div>
                <div className='flex w-full md:w-3/12 mt-12 lg:mt-0 lg:w-5/12 pb-0 items-end justify-center'>
                    <div className='relative'>
                        <img src={data?.content?.image} alt={data?.content?.name} className='z-20 px-6 relative' />
                        {/* <div className="bg-[#A0C6FF] rounded-t-full absolute left-0 right-0 bottom-0 top-0 z-10"></div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SolutionProvider