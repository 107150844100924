import Modal from "components/modal";
import Barcode from "components/molecules/Cards/Barcode";
const Services = ({ error, data, wording }) => {
  return (
    <div className="py-12">
      <div className="page-container">
        {error && <div>{wording.alert_error}</div>}
        <h1 className="text-3xl text-body-primary">{data?.header}</h1>
        <div className="grid sm:grid-cols-3 gap-4 my-12">
          {data?.list.map((item) => (
            <>
              <Barcode
                key={item.id}
                currentId={item.id}
                image={item.image || "/images/barcode / 1.png"}
                title={item.name}
                desc={item.description}
                url={item.slug}
                textLink={wording?.btn_more || "X"}
              />
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
