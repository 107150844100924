import Button from 'components/atoms/Button'
import TextLoader from 'components/atoms/Loader/TextLoader'
import HTMLReactParser from 'html-react-parser'
import { Fragment, useEffect, useState } from 'react'
import { BsChevronDown } from 'react-icons/bs'
import { FaArrowRight } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import axios from "axios";
import React from 'react';
import Popup from 'reactjs-popup';
import modal from 'components/modal'
import Modal from 'components/modal'

const MenuHeader = ({
    data,
    isLoading,
    mapping,
    wording,
    lang,
}) => {
    const navigate = useNavigate()
    const [showMegaMenu, setShowMegaMenu] = useState(false)
    const [dataCh, setDataCh] = useState(null)
    const [name, setName] = useState(null)
    const [id, setId] = useState(null)
    const [setting, setSetting] = useState(null)


    const hoverMainParent = (item) => {
        setId(item.id)
        setName(item.name)
        setDataCh(item.children)
        setShowMegaMenu(item.children ? true : false)
    }

    const handleClickMenu = (data) => {
        if (data.url != 'javascript:void(0);') {
            //const isLink = data.url.includes("http")
            const isLink = data.url.substring(0, 5)
            isLink == "https" ? 
            window.location.href = data.url : 
            navigate(
                `/${lang}${data.url}`,
                {
                    state: {
                        data: data,
                        mapping: mapping,
                    }
                })
                window.location.reload()
               
        }

        setDataCh(data.children)

      
     

    }


    const handleClickSubMenu = (data) => {
        if (data.url != 'javascript:void(0);') {

            // const isLink = data.url.substring(0, 5)
            // isLink == "https" ? 
            //  window.location.href = data.url : 
             
            navigate(
                `/${lang}${data.url}`,
                {
                    state: {
                        data: data,
                        mapping: mapping,
                    }
                })
                // setShowMegaMenu(false)
              window.location.reload()
        }

    }

    
      

    const handleClickSubMenu2 = (data2) => {
      navigate(
                `/${lang}/${data2.slug}`
                )
                // setShowMegaMenu(false)
                window.location.reload()
            }

            const FetchList = async () => {
       
                axios.get( `${process.env.REACT_APP_API}/setting`,
                ).then(res => {
             
                const list = res.data
                setSetting(list)
               
               
                return res;
                })
            }
            useEffect(() => {
                FetchList()
             }, [])

    return (
        <>
        <div className={`flex block 
        ${
            showMegaMenu == true ?
            `h-[1000px]` : ``
        }
        
        w-full absolute z-[80] `}
        onClick={() => setShowMegaMenu(false)}
        ></div>
        <div className='min-h-[60px] flex gap-3 lg:gap-5 items-center  mx-[190px]  relative  '>
            <div className='flex-1 z-[101] flex  flex-wrap gap-3'>
                {
                    isLoading && <TextLoader count={5} />
                }
                {
                    data?.map(item => (
                        <div key={item.id}
                       
                        >
                            <button
                                onClick={() => handleClickMenu(item)}
                                onMouseEnter={() => hoverMainParent(item)}
                                className='flex md:text-xs lg:text-sm items-center gap-1 py-3 text-[#2A5985] hover:text-base-orange'>
                                <span>{item.name} </span>
                                {
                                    item.children && <BsChevronDown fontSize={15} />
                                }
                            </button>
                        </div>
                    ))
                }
            </div>
            {showMegaMenu && (
                <div
                      onMouseLeave={() => setShowMegaMenu(false)}
                    
                    className='absolute z-[100] left-0 right-0  top-0 pt-[55px]'>
                    <div className="border border-blue-100 bg-[#FAFAFA] ">
                        {
                            dataCh && (
                                <MegaContent
                                    id={id}
                                    name={name  }
                                    wording={wording}
                                    lang={lang}
                                    handleClickMenu={handleClickSubMenu}
                                    handleClickMenu2={handleClickSubMenu2}
                                    data={dataCh}
                                />
                            )
                        }
                    </div>
                </div>
            )}
            <div className=''>
                <a href={setting?.link_product_registration}>
                <Button
                    varian="orange"
                    size='md'
                    label={wording?.btn_registration || "Registrasi Produk Online"}

                />
                </a>
            </div>
        </div>
        </>
    )
}

const MegaContent = ({ data, handleClickMenu, handleClickMenu2, wording, id, name, lang }) => {
    const navigate = useNavigate()
    const [openChild, setOpenChild] = useState(data[0])
    const handleOpenChild = (data) => {
        if (data.children == false && !data.content) {
         
            navigate(`/${lang}${data.url}`);
           window.location.reload()
            //console.log("klik")
        }else  if (data.children !== false && !data.content) {
            setOpenChild(data)
        }
        else  if (data.children == false && data.content !== false) {

            setOpenChild(data)
        }
       
        else  if ( !data.content) {
            navigate(`/${lang}${data.url}`);
            window.location.reload()
        }
        else{
            // if (data.children) {
            //     setOpenChild(data)
            // }
            // else if (data.content) {
            //     setOpenChild(data)
            // }

            navigate(`/${lang}${data.url}`);
        }
      
    }
    useEffect(() => {
        if (data[0].children) {
            setOpenChild(data[0].children && data[0])
        }
        if (data[0].content) {
            setOpenChild(data[0].content && data[0])
        }
        if (data[0].content == false) {
            setOpenChild(data[0])
        }
        if (data[0].children == false) {
            setOpenChild(data[0])
        }
    }, [data])

    // const [renderThird] = useState([ 1, 2, 3, 4, 5, 6])
    const [renderThird] = useState([ 1, 4])

    return (
        <>
            {
                renderThird.includes(id) ? (
                    <div className='p-4'>
                        <h3 className='text-body-primary mb-5 text-2xl'>{name}</h3>
                        <div className={`grid gap-4 ${id == 4 ? "grid-cols-4" : "grid-cols-2"} `}>
                            {data?.map(item => (
                                <Fragment key={item.id}>
                                    {
                                        id == 4 ? (
                                            <div
                                                className='p-3 justify-center items-center rounded-lg flex flex-col gap-3'>
                                                <div className='w-28 h-28'>
                                                    <img className='w-full h-full object-contain' src={item.image} alt={item.name} />
                                                </div>
                                                <h4 className='text-body-primary font-bold'>{item.name}</h4>
                                                <span className='font-light text-sm text-body-primary'>
                                                    {item.info}
                                                </span>
                                                <button
                                                    onClick={() => handleClickMenu(item)}
                                                    className='text-base-orange font-semibold'>
                                                    {wording?.more || "More Details"}
                                                </button>
                                            </div>
                                        ) : (
                                            <div
                                                className='bg-[#F2F6FF] p-3 rounded-lg flex flex-col items-start gap-3'>
                                                <h4 className='text-body-primary font-bold'>{item.name}</h4>
                                                <span className='font-light text-sm text-body-primary'>
                                                    {item.info}
                                                </span>

                                                <button
                                                    onClick={() => handleClickMenu(item)}
                                                    className='text-base-orange font-semibold'>
                                                    {wording?.more || "More Details"}
                                                </button>
                                            </div>
                                        )
                                    }
                                </Fragment>
                            ))
                            }
                        </div>
                    </div >
                ) : (
                    <div className="grid grid-cols-4">
                        <div className='p-5 bg-[#E9F5FC] text-body-primary'>
                            <h3 className='text-body-primary mb-5 text-2xl'>{name} </h3>
                            {
                                data?.map(item => (
                                    <button
                                         onClick={() => handleOpenChild(item)}
                                      
                                        key={item.id}
                                        className='w-full flex justify-start items-center text-sm my-4 group gap-3'>
                                        <span className='text-left text-[#5A5A5A] font-normal group-hover:font-semibold duration-200'>{item.name }</span>
                                        {
                                            item?.children || item?.content ? <FaArrowRight /> : undefined
                                        }
                                    </button>
                                ))
                            }
                        </div>
                        {
                            id == 6 ? (
                                <div className='col-span-3  p-5'>
                                    <div className="flex gap-3 items-center">
                                        <h3 className='text-body-primary text-2xl'>{name} - {openChild?.name}</h3>
                                        <button
                                            onClick={() => handleClickMenu(openChild)}
                                            className='text-slate-600 font-light'>
                                            {wording?.more || "See all"}
                                        </button>
                                    </div>
                                    <div className='grid gap-4 grid-cols-2 my-6'>
                                        {
                                            openChild?.content && openChild?.content?.map(item => (
                                                <div
                                                    key={item.id}
                                                    className='p-3 rounded-lg flex flex-col relative items-start gap-3'>
                                                    {
                                                        id == 3 ? (

                                                            <a
                                                                className='absolute top-2 right-2'
                                                                href={item.url} target="_blank" rel="noopener noreferrer">
                                                                <img src="/icons/_ic_top_right.svg" alt="tr" />
                                                            </a>
                                                        ) : undefined
                                                    }
                                                    <h4 className='text-body-primary font-bold'>{item.name}</h4>
                                                    <div className='flex gap-2'>
                                                        <div>
                                                            <p className='line-clamp-2'>
                                                                <span className='text-slate-600 text-lg'>{item.publish}</span>
                                                                {item.overview && <span className='mx-2'>-</span>}
                                                                <span className='text-slate-500'>
                                                                    {item.overview}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* <Link to={`/${lang}/${item.start}/${item.slug}`}> */}
                                                    <button
                                                         onClick={() => handleClickMenu2(item)}
                                                        className='text-base-orange font-semibold'>
                                                        {wording?.more || "More Details"} 
                                                    </button>
                                                    {/* </Link> */}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ) : (
                                <div className='col-span-3 p-5'>
                                    <h3 className='text-body-primary mb-5 text-2xl'>{openChild?.name} - {name}</h3>
                                    <div className='grid gap-4 grid-cols-3'>
                                     
                                        <>

                                        {
                                            openChild?.children && openChild?.children?.map(item => (
                                                <>
                                                {/* {
                                                    item.children !== false ? */}
                                                    <div
                                                  
                                                    className='bg-[#F2F6FF] p-3 rounded-lg flex flex-col relative items-start gap-3'>
                                                    {
                                                        id == 3 ? (
                                                            item.id == 27 || item.id ==  28 ? ( <Modal wording={wording?.more  || "More Details"}  url={item.url} isIcon={1}></Modal>) : ( <a
                                                                className='absolute top-2 right-2'
                                                                href={
                                                                    item.url.substring(0, 5) == "https" ? 
                                                                    item.url
                                                                    :
                                                                    `/${lang}${item.url}`
                                                                    } target={
                                                                        item.url.substring(0, 5) == "https" ? 
                                                                        "_blank"
                                                                        :''
                                                                        } rel="noopener noreferrer">
                                                                <img src="/icons/_ic_top_right.svg" alt="tr" />
                                                            </a>)
                                                           
                                                        ) : 
                                                        undefined
                                                    }
                                                    {/* INI BUAT GS 1 ID 27 */}
                                                    <h4 className='text-body-primary font-bold'>{item.name} </h4>
                                                    <span className='font-light text-sm text-body-primary'>
                                                        {item.info && item.info} 
                                                    </span>
                                                    { item.id == 27 || item.id == 28 ? ( <Modal wording={wording?.more  || "More Details"} isButton={1} url={item.url}></Modal>) : (<a  href={
                                                            item.url.substring(0, 5) == "https" ? 
                                                            item.url
                                                            :
                                                            `/${lang}${item.url}`
                                                            }

                                                            target={
                                                                item.url.substring(0, 5) == "https" ? 
                                                                "_blank"
                                                                :''
                                                                }
                                                    >
                                                    
                                                     <button
                                                        // onClick={
                                                        //     item.url.substring(0, 5) == "https" ? 
                                                          
                                                        //      window.open(item.url)
                                                          
                                                          
                                                        //     :

                                                        //     () => handleClickMenu(item)
                                                        // }
                                                        className='text-base-orange font-semibold'>
                                                        {wording?.more  || "More Details"}
                                                    </button> 
                                                    </a>)}
                                                    
                                                </div>
                                                    {/* :
                                                   ""
                                                } */}
                                              
                                                </>
                                            ))
                                        }
                                        </>
                                    </div>
                                </div>

                            )
                        }
                    </div>
                )
            }
        </>
    )
}

export default MenuHeader
