import axios from 'axios';
import PageLoader from 'components/atoms/Loader/PageLoader';
import CardSolution from 'components/molecules/Cards/CardSolution';
import CardBulletin from 'components/molecules/Cards/CardBulletin';
import parse from 'html-react-parser';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { setTranslation } from 'redux/global/translationSlice';
import { setBreadcrumb } from 'redux/navigation/breadcrumbSlice';
import { setSidebar } from 'redux/navigation/sidebarSlice';
import useSWR from 'swr';
import Pagination from 'react-bootstrap/Pagination';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import { SuperSEO } from "react-super-seo";

const fetcher = async (url) => {
    const response = await axios({
        method: 'get',
        url: url
    })
    return response.data
}
const MainPage = () => {
    const dispatch = useDispatch()
    const breadcrumb = useSelector(state => state.breadcrumb.value)
    const URL = process.env.REACT_APP_API
    const loc = useLocation()
    const lang = reactLocalStorage.getObject("gs_lang").code
    const wording = useSelector(state => state.wording.value)
    const slug = loc.pathname.split("/").pop()
    const { data, isLoading } = useSWR(`${URL}/${lang}/contact/${slug}`, fetcher)
    const translation = data?.category?.translation

    // let active = 2;
    // let items = [];
    // for (let number = 1; number <= 5; number++) {
    //   items.push(
    //     <Pagination.Item key={number} active={number === active}>
    //       {number}
    //     </Pagination.Item>,
    //   );
    // }


    useEffect(() => {
        document.title = isLoading? "GS1 Indonesia" : data?.category?.name;
        dispatch(setTranslation(translation && Object.values(translation)[0]))
        dispatch(setSidebar(data?.navigation?.sidebar || []))
        dispatch(setBreadcrumb(data?.navigation?.breadcrumb || []))
    }, [data])

    return (
        // slug == "gs1-indonesias-solution-provider" ? 
        <>
         <SuperSEO
        description={data?.category?.description}
        />
        <div className='mb-5'>
            <Breadcrumbs data={breadcrumb} />
            <div className='mb-12'>
                <h1 className='text-body-primary text-2xl mb-5'>
                    {data?.category?.name}
                </h1>
                <article>
                    {parse(data?.category?.description || "")}
                </article> 
            </div>
            <div>
            
               

<div className=' items-center border rounded-lg group px-3'>
                    
                    <Tab.Container id="left-tabs-example" defaultActiveKey={0} >
{
    isLoading && <PageLoader type="article" />
}
    <Row>
    <Nav variant="pills" className="flex-row">
        {
    data?.list?.map((item,i) => ( 
        <Nav.Item>
        <Nav.Link eventKey={i}>{item.name}</Nav.Link>
        </Nav.Item>
           ))
        }
        </Nav>
   
    
</Row>
<Row className='mt-2'>
<Tab.Content>
    {
    data?.list?.map((item,i) => ( 
    <Tab.Pane eventKey={i}>
         <div dangerouslySetInnerHTML={{ __html: item.description }}/>
    </Tab.Pane>
      ))
    }
</Tab.Content>
</Row>
</Tab.Container>
</div>
             
            </div>
        </div>
      
        </>

    )
}

export default MainPage