import React from 'react'

const FormInput = ({
    type = "text",
    placeholder = "Input text",
    id,
    name,
    label,
    required,
    isLongText,
    ...rest
}) => {
    return (
        <div className='my-2'>
            <label className='text-md mb-1 font-[300]  text-body-primary block' htmlFor="id">
                <span>{required && "*"}{label}</span>
            </label>
            {
                isLongText ? (
                    <textarea
                        rows={3}
                        id={id}
                        name={name}
                        type={type}
                        {...rest}
                        required={required}
                        className='w-full text-[#555] bg-[#F4F4F4] p-2 outline-none focus:ring-blue-200 focus:ring-2 ring-1 ring-slate-300 rounded-md'
                    />
                ) : (
                    <input
                        id={id}
                        name={name}
                        type={type}
                        {...rest}
                        required={required}
                        className='w-full text-[#555] bg-[#F4F4F4] p-2 outline-none focus:ring-blue-200 focus:ring-2 ring-1 ring-slate-300 rounded-md'
                        placeholder={placeholder}
                    />
                )
            }
        </div>
    )
}

export default FormInput