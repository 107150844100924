import axios from 'axios'
import Button from 'components/atoms/Button'
import FormInput from 'components/atoms/FormInput'
import PageLoader from 'components/atoms/Loader/PageLoader'
import Breadcrumbs from 'components/molecules/Breadcrumbs'
import Sidebar from 'components/molecules/Sidebar'
import HTMLReactParser from 'html-react-parser'
import { useEffect, useState } from 'react'
import { FaArrowLeft, FaUserCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { reactLocalStorage } from 'reactjs-localstorage'
import { setTranslation } from 'redux/global/translationSlice'
import { setBreadcrumb } from 'redux/navigation/breadcrumbSlice'
import { setSidebar } from 'redux/navigation/sidebarSlice'
import useSWR from 'swr'
import { SuperSEO } from "react-super-seo";
const fetcher = async (url) => {
    const response = await axios({
        method: 'get',
        url: url
    })
    return response.data
}
const Detail = ({
    artObj, 
    artObjId, 
    artObjSlug, 
    artObjSlugId, 
    objLagi,
    ObjSlug5new,
    ObjSlug5newId, 
    objLagiEn}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const sidebar = useSelector(state => state.sidebar.value)
    const breadcrumb = useSelector(state => state.breadcrumb.value)
    const path = location.pathname.split("/")
    const slug = location.pathname.split("/").pop()
    const [currentSlug, setCurrentSlug] = useState(path[3])
   
    const [currentType, setCurrentType] = useState("article")
    const [current, setCurrent] = useState(path[2])
    const lang = reactLocalStorage.getObject("gs_lang").code
    const rootPath = reactLocalStorage.getObject("rootPath")
    const { data: datawording } = useSWR(`${process.env.REACT_APP_API}/wording`, fetcher);
    const wording = datawording && datawording[lang]
    const { data, error, isLoading } = useSWR(`${process.env.REACT_APP_API}/${lang}/${currentType}/${slug}`, fetcher);
    
    // const translation = data && Object.values(data?.translation)[0]
    const translation = lang == "en" ? data&&data.translation.id_ID.slug : data && Object.values(data?.translation)[0]
    useEffect(() => {
        document.title = isLoading? "GS1 Indonesia" :data?.name;
         dispatch(setTranslation({
             id: translation?.id,
             name: translation?.name,
             slug: rootPath + "/" + translation?.slug,
         }))
        dispatch(setSidebar(data?.navigation?.sidebar || []))
        dispatch(setBreadcrumb(data?.navigation?.breadcrumb || []))
    }, [dispatch, data])


    //console.log("side bar", data?.navigation?.sidebar)

    const [formOpen, setFormOpen] = useState(false)
    const [form, setForm] = useState(
        {
            company_name: "",
            contact_name: "",
            email: "",
            designation: "",
            telephone: "",
            description: ""
        }
    )

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault() 
        try {
            const response = await axios({
                url: process.env.REACT_APP_API + "/form/partner",
                method: "POST",
                headers: {
                    'Content-Type': "multipart/form-data",
                },
                data: { ...form, article_id: data?.id }
            })
            if (response?.data?.code === 1) {
                toast.success(response.data.message)
                setFormOpen(false)
                setForm({
                    company_name: "",
                    contact_name: "",
                    email: "",
                    designation: "",
                    telephone: "",
                    description: ""
                })
            } else {
                toast.error(response.data.error)
            }
        } catch (error) {
            //console.log(error)
        }
        finally {
            //console.log("FINAL")
        }
    }
    //console.log("sidebar", sidebar)
    //console.log("artObj", artObj)
    //console.log("artObjId", artObjId)
    
    //console.log("artObjSlugId", artObjSlugId)
    //console.log("current", current)
    //console.log("objLagi", objLagi)
    //console.log("objLagi En", objLagiEn)
    //console.log("ObjSlug5", ObjSlug5new)
    
    
    return (
        <>
           <SuperSEO
        description={data?.description}
        />
            {
                isLoading && <PageLoader type="article" />
            }
             {
                           artObj == "partner" ||   artObjId == "partner"?
            <div className='flex justify-center min-h-[40vh] items-center'>
                <img src={data?.image} alt={data?.name} />
            </div>
            :""}
             {/* {
                            artObj == "partner" ||   artObjId == "partner"? */}
            <div className="page-container">
           
                <div className='flex md:gap-6'>
                    <div className='w-0 overflow-hidden md:w-[320px]'>
                        <Sidebar data2={sidebar} ObjSlug5={ObjSlug5new} ObjSlug5newId={ObjSlug5newId}  ObjSlug={artObj}  ObjSlug2={artObjId} ObjSlug3={artObjSlug} ObjSlug4={artObjSlugId} objLagi={objLagi} objLagiEn={objLagiEn} isLoading={isLoading}/>
                    </div>
                    <div className='flex-1'>
                        <Breadcrumbs data={breadcrumb} />
                         {
                            artObj == "partner" ||   artObjId == "partner"?
                            <>
                            {
                                formOpen ? (
                                    <div className='mb-12'>
                                        <div className="flex gap-2 md:gap-3 py-5">
                                            <button
                                                onClick={() => setFormOpen(false)}
                                                className='text-body-primary'>
                                                <FaArrowLeft />
                                            </button>
                                            <h2 className="text-lg md:text-xl font-semibold text-body-primary">Submit Contact Request</h2>
                                        </div>
                                        <form
                                            onSubmit={handleSubmit}
                                            className='border p-4 lg:p-8 rounded-md bg-white'>
                                            <FormInput
                                                onChange={handleChange}
                                                required
                                                label={wording?.form_company || "Company Name"}
                                                placeholder={wording?.form_company || "Company Name"}
                                                id="company_name"
                                                name="company_name"
                                            />
                                            <div className="grid gap-3 lg:grid-cols-2 my-3">
                                                <FormInput
                                                    onChange={handleChange}
                                                    required
                                                    label={wording?.form_contact || "Contact Name"}
                                                    placeholder={wording?.form_contact || "Contact Name"}
                                                    id="contact_name"
                                                    name="contact_name"
                                                />
                                                <FormInput
                                                    onChange={handleChange}
                                                    label={wording?.form_designation || "Designation"}
                                                    placeholder={wording?.form_designation || "Designation"}
                                                    id="designation"
                                                    name="designation"
    
                                                />
                                                <FormInput
                                                    required
                                                    onChange={handleChange}
                                                    label={wording?.form_email || "Email"}
                                                    placeholder={wording?.form_email || "Email"}
                                                    id="email"
                                                    name="email"
                                                    type='email'
                                                />
                                                <FormInput
                                                    required
                                                    onChange={handleChange}
                                                    label={wording?.form_phone || "Telepon"}
                                                    placeholder={wording?.form_phone || "Telepon"}
                                                    id="telephone"
                                                    name="telephone"
                                                    type='number'
                                                />
                                            </div>
                                            <FormInput
                                                required
                                                onChange={handleChange}
                                                isLongText
                                                label={wording?.form_messsage || "Describe your needs/requirements"}
                                                placeholder={wording?.form_messsage || "Describe your needs/requirements"}
                                                id="description"
                                                name="description"
                                                type='text'
                                            />
                                            <div className="flex justify-end">
                                                <Button
                                                    label={wording?.btn_submit || 'Submit'}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                ) : (
                                    <>
                                        <article>
    
                                            <div className='flex gap-2 items-start py-3 border-b'>
                                                <h1 className='flex-1'>{data?.name}</h1>
                                                <Button
                                                    shadow
                                                    onClick={() => setFormOpen(true)}
                                                    icon={<FaUserCircle />}
                                                    size="xs"
                                                    label={wording?.btn_request || "Request for contact"}
                                                />
                                            </div>
                                            {HTMLReactParser(data?.description || "")}
                                        </article>
                                    </>
                                )
                            }
                            </>
                           
                            :
                            <article>
    
                                            <div className='flex gap-2 items-start py-3 border-b'>
                                                <h1 className='flex-1'>{data?.name}</h1>
                                                
                                            </div>
                                            {HTMLReactParser(data?.description || "")}
                                        </article>
                        } 
                       
                    </div>
                </div>
               

            </div>
       {/*      :
            <>
             <Sidebar data2={sidebar} ObjSlug5={ObjSlug5new}  ObjSlug={artObj}  ObjSlug2={artObjId} ObjSlug3={artObjSlug} ObjSlug4={artObjSlugId} objLagi={objLagi} objLagiEn={objLagiEn} isLoading={isLoading}/>
             <article>
 
             <div className='flex gap-2 items-start py-3 border-b'>
                 <h1 className='flex-1'>{data?.name}</h1>
                 
             </div>
             {HTMLReactParser(data?.description || "")}
         </article>
         </>
        }

        */}


        </>
    )
}

export default Detail