import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    value: false,
}

export const mobileMenuSlice = createSlice({
    name: "mobilemenu",
    initialState,
    reducers: {
        toggleMenu: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { toggleMenu } = mobileMenuSlice.actions

export default mobileMenuSlice.reducer