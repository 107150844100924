import React, { useState } from "react";
import Popup from "reactjs-popup";
import "../../index.css";
import WordingEN from "./wordingEN";
import WordingID from "./wordingID";
import { reactLocalStorage } from "reactjs-localstorage";

const Modal = ({ wording, isButton, url, isIcon, isFooter }) => {
  const langStorage = reactLocalStorage.getObject("gs_lang").code;

  return (
    <div>
      <Popup
        modal
        nested
        trigger={
          isFooter == 1 ? (
            <a>
              <span className="cursor-pointer text-[#21A1C9] text-[14px] leading-[26px] hover:underline">
                <u>{wording} </u>
              </span>
            </a>
          ) : isIcon == 1 ? (
            <button
              className="absolute top-2 right-2"
              rel="noopener noreferrer"
            >
              <img src="/icons/_ic_top_right.svg" alt="tr" />
            </button>
          ) : isButton == 1 ? (
            <button className="text-base-orange font-semibold">
              {wording}
            </button>
          ) : (
            <button
              onClick={() => {}}
              className="text-body-primary block mx-6 mb-2"
            >
              {" "}
              <u>{wording}</u>{" "}
            </button>
          )
        }
        // {...{ Tes }}
        position="center"
      >
        <div>
          {langStorage == "en" || wording == "See More" ? (
            <WordingEN></WordingEN>
          ) : (
            <WordingID></WordingID>
          )}

          <div
            position="center"
            class="cursor-pointer select-none flex justify-center items-center mt-5 mb-5"
          >
            <div
              onClick={() => {
                // window.location.href = url;
                window.open(url);
              }}
              class="loginButtonSection bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {langStorage == "en" || wording == "See More"
                ? "Next"
                : "Selanjutnya"}
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Modal;
