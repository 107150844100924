import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { reactLocalStorage } from "reactjs-localstorage"
const lang = reactLocalStorage.getObject("gs_lang").code || "en"
export const getMenuFooter = createAsyncThunk("footer/getMenuFooter", async () => {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}/${lang}/menu/footer`
    })
    return response.data
})

const menufooterEntity = createEntityAdapter({
    selectId: (footer) => footer.id
})

const menufooterSlice = createSlice({
    name: "menufooter",
    initialState: menufooterEntity.getInitialState(),
    extraReducers: {
        [getMenuFooter.pending]: (state, action) => {
            state.isLoading = true
        },
        [getMenuFooter.fulfilled]: (state, action) => {
            menufooterEntity.setAll(state, action.payload)
            state.isLoading = false
        },
    }
})

export const menufooterSelectors = menufooterEntity.getSelectors(state => state.menufooter)

export default menufooterSlice.reducer