
import React from 'react';

class WordingEN extends React.Component {
  render() {
    return  <div className='m-3'>
    <div className='head' >
        <b class="text-xl "><center>Terms and condition</center></b>
    </div>
    {/* Restrictions on Data Use. */}
    <b>Restrictions on Data Use. </b><br/>
    Data Recipient:
    <ul class=" list-outside list-[upper-alpha]">
        <li>
            Shall not present, publish or use Data in a manner that is false or misleading; infringes rights of third parties (including Data Provider or Designee’s rights) and/or violates any applicable laws and regulations;
        </li>
        <li>
            Shall not modify the content of Data in publishing or disseminating such Data (unless it has express written authorisation of Data Provider or Designee to do so);
        </li>
        <li>
            Shall not publish or use Data in a manner that implies any endorsement by Data Provider, Designee or GS1 (unless it has express written authorisation of the Data Provider, the Designee or GS1 to do so);
        </li>
        <li>
            Shall only use the Data in accordance with the Permitted Use applicable to the Solution [see “Permitted Use of the Solution(s)” below]; and
        </li>
        <li>
            Shall not decompile, reverse-engineer, alter, or in any way tamper (or attempt to do so) with all or part of the of the [Mos solution] or any software or solution comprised therein or connected thereto, nor cause, permit or assist any other person directly or indirectly to do any of the above. Clauses [a)-e)] above shall survive any termination of access to the Solution.”
        </li>
    </ul>
    <hr />
    {/* Permitted Use for the Solution.  */}
    <b>Permitted Use for the Solution. </b><br/>
    Data made available to Data Recipient via the Verified by GS1 (“VbG”) Solutions shall be subject to the following terms of use (collectively, the “Permitted Use”):
    <ul class=" list-outside list-[upper-alpha]">

        <li>
        [Solution Offering via API] Data Recipient may not use, sell, sublicense, distribute or otherwise make available the Data to third parties, unless such use is part of a Value-Added Product. Data Recipient shall also ensure that any third party (including, but not limited to, solution providers, agents, subsidiaries, or sub-contractors) is also bound to comply with the restrictions on use set out in this section [Permitted Use] and section [Restrictions on Use]. GS1 [MO NAME] reserves the right to immediately
            remove Data Recipient’s API access if Data Recipient is suspected or found to be in breach of the terms of this
            section [#]. For the purpose of this section, “Value-Added Product” means a product or service offered by Data Recipient to its end-users that uses the Data and adds appreciable value to it, including, without limitation, by
            combining it with other data, information or analyses sourced or developed by Data Recipient or by processing or presenting the Data in a novel way. Replicating the Data and/or the Service shall not be considered as adding
            appreciable value; and
        </li>
        <li>
        [for Other Solution Offerings] Data Recipient may use the Data solely within its business and for its own business processes, excluding any commercial use (‘commercial use’ meaning any use where the Data is sold, leased, licensed or otherwise made available as a whole or in
            part, on its own or as part of another product/service). Data Recipient shall not share, release, submit or allow extraction of the Data by any party other than its own employees or agents.
        </li>
    </ul>
    <hr />
    {/* Inactive Licence.  */}
    <b>Inactive Licence. </b><br/>
    In the event that Data Recipient encounters a GTIN that relates to an Inactive Licence,
    <ul class=" list-outside list-[lower-roman]">
        <li>Data Recipient shall assist GS1 by notifying GS1 of said GTIN; and</li>
        <li>if Data Recipient is a sales organisation, GS1 recommends that it also notify its suppliers of such Inactive Licence according to the Solution for supplier to notify its respective MO. For the purpose of this section, “Inactive License” means a GS1
            identification key licence that has either expired or terminated under the applicable terms and conditions.</li>
    </ul>
    <hr />
    {/* Suspension & Termination.  */}
    <b>Suspension & Termination.  </b><br/>
    GS1 may suspend Data Recipient's access to and use of any Data originating from the Solution(s) with immediate effect if Data Recipient (or an Authorised User of
Data Recipient) commits a material breach of any provision of these Terms of Use and until such breach is cured, or in order to prevent imminent damage to a third party. If the breach remains uncured for [e.g. fifteen (15)] consecutive days, GS1
shall have the right to terminate Data Recipient’s access to and use of any Data originating from the Solution(s) with immediate effect. These Terms of Use shall survive any termination of Data Recipient’s access to the Data Out Solution(s).
    <hr />
    {/* Disclaimer of Warranties.  */}
    <b>Disclaimer of Warranties.</b><br/>
        The Solution(s) displays Data that is not owned nor controlled by GS1 AISBL,GS1 Indonesia, or GS1 Member Organisations, therefore the Data is made available on an 'as is' and 'as available' basis. Verifications available from the Solution(s) are limited to certain automated logical checks and do not include physical or legal validations of the accuracy of the Data. Neither GS1 nor the GS1 Member Organisations, Data Providers nor Designees represent or warrant that the
        Data is accurate, complete and/or up-to-date. Use of the Data, and the Solution(s) is at Data Recipient’s sole risk. GS1 does not represent or warrant that the Solution(s) will be secure or free from error or interruption. Neither GS1 AISBL nor GS1 Indonesia represent or warrant that the Solution(s) and the Data are suitable for any regulatory purpose, including without limitation any regulatory reporting requirements in the healthcare sector. To the fullest extent permitted by law, GS1 makes no representations or warranties, express, implied or otherwise, regarding any matter, including the Data, and the Solution(s). Any such representations or warranties are expressly disclaimed.
    <hr />
    {/* Limitation of Liability.  */}
    <b>Limitation of Liability. </b><br/>
    To the fullest extent permitted by law, GS1 AISBL, GS1 Indonesia, GS1 Member Organisations, Data Provider and/or Designee shall not be liable for any damages whatsoever, including but not limited to actual, direct, consequential, indirect, incidental or punitive damages, whether such liability is based on breach of contract, breach of warranty or otherwise, even if advised of the possibility of
such damages, that may arise from Data Recipient’s use of the Solution(s) and/or the Data or for any failure or refusal by GS1 to grant Data Recipient access to the Solution(s) and/or the Data, or any harm, effects or damages suffered by Data Recipient as a result thereof.
    <hr />
    {/* Indemnity.  */}
    <b>Indemnity.</b><br/>
    Data Recipient shall fully indemnify, hold harmless and defend GS1 AISBL, GS1 Indonesia and the GS1 Member Organisations from and against all claims, actions, damages, liabilities, obligations, losses, settlements, judgments, costs and expenses (including reasonable attorneys’ fees and costs), brought by any third party which arise out of, relate to or result from any use by Data Recipient of the Data, the Solution(s) or the GS1 Registry Platform in breach of these Terms of Use or any applicable laws or regulations.
</div>;
  }
}

export default WordingEN;