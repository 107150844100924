import Sidebar from 'components/molecules/Sidebar'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

const AppLayout = ({obj, obj2, obj3, newObj2ArticleId, path}) => {
    
    const objNew = obj?obj.toString() : "";
    const objNewId = obj2?obj2.toString() : "";
    //console.log("objNew", objNew)
    //console.log("objNewId",objNewId)
    const sidebar = useSelector(state => state.sidebar.value)
    //console.log("Path",path)
    //console.log("obj3", obj3)
    //console.log("newObj2ArticleId layout", newObj2ArticleId)
    
    return (
        <div className='bg-[#FAFAFA]'>
            <div className={`
            ${obj3 =="article" || newObj2ArticleId == "article" || objNew == "product" || objNewId =="product" ?
            "" : "page-container" 
            }
            `}>
                <div className='flex md:gap-6'>
                {
                    objNew == "category" || objNew == "product" || objNew == "catalog" || objNewId =="category" || objNewId =="product" || objNewId =="catalog" || obj3 =="article" || newObj2ArticleId == "article" || path=="search" ?
                        ""
                        :
                        <div className='w-0 overflow-hidden md:w-[320px]'>
                            <Sidebar data={sidebar} />
                        </div>
                }
                  
                    <div className='flex-1'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppLayout