import axios from 'axios';
import PageLoader from 'components/atoms/Loader/PageLoader';
import CardSolution from 'components/molecules/Cards/CardSolution';
import CardBulletin from 'components/molecules/Cards/CardBulletin';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { setTranslation } from 'redux/global/translationSlice';
import { setBreadcrumb } from 'redux/navigation/breadcrumbSlice';
import { setSidebar } from 'redux/navigation/sidebarSlice';
import useSWR from 'swr';
import Pagination from '@mui/material/Pagination';
import Table from 'react-bootstrap/Table';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import { SuperSEO } from "react-super-seo";

const fetcher = async (url) => {
    const response = await axios({
        method: 'get',
        url: url
    })
    return response.data
}
const MainPage = ({ code }) => {
    const dispatch = useDispatch()
    const URL = process.env.REACT_APP_API
    const loc = useLocation()
    const breadcrumb = useSelector(state => state.breadcrumb.value)
    const lang = reactLocalStorage.getObject("gs_lang").code
    const wording = useSelector(state => state.wording.value)
    const [member, setMember] = useState([])
    const [page, setPage] = useState()
    const [huruf, setHuruf] = useState('')
    const [hurufVal, setHurufVal] = useState('')
    const [pageCount, setPageCount] = useState()
    const [pageVal, setPageVal] = useState(1)
    const [limit, setLimit] = useState()
    const [loading, setLoading] = useState(false)
    const slug = loc.pathname.split("/").pop()
    //console.log("code", code)
    const { data, isLoading } = useSWR(
        `${URL}/${lang}/content/${slug}` , fetcher)


    const translation = data?.category?.translation

    //console.log("huruf", huruf)

   const FetchData = async () => {
        setLoading(true)
        axios.get( `https://cms.gs1id.org/api-mobile/?module=member&page=1&search=${huruf}`,
        ).then(res => {
        //  const areas = res.data.Data
        //   setAreas(res.data.Data)
        setLoading(false)
        setMember(res.data.data)
        setPage(res.data.pagination.page)
        setLimit(res.data.pagination.limit)
        let pagecount = Math.ceil(res.data.pagination.total / res.data.pagination.limit)
        setPageCount(pagecount )
        return res;
        })
    }

  //console.log("pages count", pageCount)

  const handleHuruf = async (a) => {
    setHurufVal(a)
    setLoading(true)
    axios.get( `https://cms.gs1id.org/api-mobile/?module=member&page=${pageVal}&search=${a}`,
    ).then(res => {
    //  const areas = res.data.Data
    //   setAreas(res.data.Data)
    setLoading(false)
    setMember(res.data.data)
    setPage(res.data.pagination.page)
    setLimit(res.data.pagination.limit)
    let pagecount = Math.ceil(res.data.pagination.total / res.data.pagination.limit)
    setPageCount(pagecount )
    return res;
    })
}

  const handlePage = async (val) => {
    //console.log("val", val)
    setPageVal(val)
    setLoading(true)
    axios.get( `https://cms.gs1id.org/api-mobile/?module=member&page=${val}&search=${hurufVal}`,
    ).then(res => {
    //  const areas = res.data.Data
    //   setAreas(res.data.Data)
    setLoading(false)
    setMember(res.data.data)
    setPage(res.data.pagination.page)
    setLimit(res.data.pagination.limit)
    let pagecount = Math.ceil(res.data.pagination.total / res.data.pagination.limit)
    setPageCount(pagecount )
    return res;
    })
}


    useEffect(() => {
        document.title = isLoading? "GS1 Indonesia" : data?.name;
        FetchData();
        dispatch(setTranslation(translation && Object.values(translation)[0]))
        dispatch(setSidebar(data?.navigation?.sidebar || []))
        dispatch(setBreadcrumb(data?.navigation?.breadcrumb || []))
    }, [data])

    return (
        <>
        <SuperSEO
        description={data?.description}
        />
                    <div className='content'>
                        <Breadcrumbs data={breadcrumb} />
                        <div className='mb-12'>
                            <h1 className='text-body-primary text-2xl mb-5'>
                            {
                        isLoading && <PageLoader type="article" />
                    }
                                {data?.name}
                            </h1>
                            <div className="row">
                            <div class="input-group mb-2 mr-sm-2">
    <div class="input-group-prepend">
      <div class="input-group-text" id='icon-search'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
      </div>
    </div>
    <input type="text" class="form-control" id="inlineFormInputGroupUsername2" placeholder="Type / Search"
    value={huruf}
   onChange={(e) => setHuruf(e.target.value)}
    onKeyPress={(e) => {
        if (e.key === "Enter") {
            handleHuruf(e.target.value)
        }
    }}
    />
    <div class="input-group-prepend"
    onClick={() => handleHuruf(huruf)}
    >
      <div class="input-group-text" id='search-button'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ee7442" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg>
      </div>
    </div>
  </div>
                            </div>
                           <div className="row mt-3">
                           <div class="btn-group btn-group-sm text-[#004c95]" role="group" aria-label="Basic example">
                                

                            </div> 

                            <div className="list-button">
                           
                            <button type="button" class="btn"
                            onClick={()=>FetchData()}
                            
                            >All</button>
                             
                                <button type="button" class="btn" onClick={()=>handleHuruf('A')}>A</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('B')}>B</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('C')}>C</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('D')}>D</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('E')}>E</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('F')}>F</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('G')}>G</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('H')}>H</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('I')}>I</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('J')}>J</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('K')}>K</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('L')}>L</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('M')}>M</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('N')}>N</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('O')}>O</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('P')}>P</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('Q')}>Q</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('R')}>R</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('S')}>S</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('T')}>T</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('U')}>U</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('V')}>V</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('W')}>W</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('X')}>X</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('Y')}>Y</button>
                                <button type="button" class="btn" onClick={()=>handleHuruf('Z')}>Z</button>
                            </div>
                           </div>
                           {
                            loading == true ?
                            <PageLoader type="article" />
                            :
                            <>
                              <div className="row mt-3 table-anggota">
                           <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>GLN</th>
                                        <th>Nama Perusahaan</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        member?.map((list,i) => (
                                            <tr>
                                        <td>{i+1}</td>
                                        <td>
                                           <strong> {list.gln_number} </strong>
                                            </td>
                                        <td>{list.company_name}</td>
                                        
                                    </tr>
                                         ))}
                                    
                                    
                                 
                                </tbody>
                            </Table>
                       
                           </div>

                           <div className="flex row border p-3 justify-between">
                            <div className="col col-auto">
                                <span className='text-gray-400'> Halaman {page} dari {pageCount}</span>
                            </div>
                            <div className="col col-auto">
                            <Pagination 
                            count={pageCount} 
                            page={pageVal}
                            shape="rounded" 
                            showFirstButton showLastButton
                             onChange={(e, value) => handlePage(value) }
                            /> 
                             
                            </div>
                           </div>
                            </>
                           }

                         
                          
                           
                        </div>
                        <div>



                        </div>
                    </div> 
                    </>

    )
}

export default MainPage