import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { reactLocalStorage } from "reactjs-localstorage"
const lang = reactLocalStorage.getObject("gs_lang").code || "en"
export const getTop = createAsyncThunk("menutop/getTop", async () => {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}/${lang}/menu/top`
    })
    return response.data
})

const menutopEntity = createEntityAdapter({
    selectId: (menutop) => menutop.id
})

const menutopSlice = createSlice({
    name: "menutop",
    initialState: menutopEntity.getInitialState(),
    extraReducers: {
        [getTop.pending]: (state, action) => {
            state.isLoading = true
        },
        [getTop.fulfilled]: (state, action) => {
            menutopEntity.setAll(state, action.payload)
            state.isLoading = false
        },
    }
})

export const menutopSelectors = menutopEntity.getSelectors(state => state.menutop)

export default menutopSlice.reducer