import Modal from 'components/modal'
import React from 'react'
import FooterLink from './FooterLink'

const FooterBlock = ({ data }) => {

    return ( 
      
            <ul className='list-foo '>
                <li>
            <h3 className='text-[#19568F] text-sm lg:text-[16px] pb-2 font-semibold text-shadow-black'>{data.name}</h3>
            </li>
            {/* <div className='flex mt-3 md:mt-5 flex-col gap-3 md:gap-6'> */}
                {
                    data?.children?.map(item => (
                        
                        item.id == 61 || item.id == 63 ? (  <li>
                            <Modal wording={item.name} isButton={0} url={item.url} isFooter={1} ></Modal>
                            </li>) : (  <li>
                        <FooterLink
                            url={item.url}
                            name={item.name } 
                            key={item.id}
                        />
                        </li>)
                      
                        
                    ))
                }
            {/* </div> */}
          
            </ul>
      
    )
}

export default FooterBlock