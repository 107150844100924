import { useRef } from "react";
import axios from "axios"
import TextLoader from "components/atoms/Loader/TextLoader"
import { reactLocalStorage } from "reactjs-localstorage"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay, Navigation} from "swiper";
import { Carousel, Card, Stack, Button } from "react-bootstrap";

import 'swiper/css/pagination';
 
// import required modules

import useSWR from 'swr'
const fetcher = async (url) => {
    const response = await axios({
        method: 'get',
        url: url
    })
    if (response.status === 200) {
        return response.data.footer.banner
    }
}
const Mui = () => {
    const langObj = reactLocalStorage.getObject("gs_lang")
    const lang = langObj.code ? langObj.code : "id"
    const { data } = useSWR(`${process.env.REACT_APP_API}/${lang}/all`, fetcher);
 
  
    SwiperCore.use([Autoplay]);

    
    return (
        <div className="mui">
             
            <div className="page-container">
          
                <div className=" my-20">
              
                <Swiper
 slidesPerView={3}
breakpoints={{
    320: {
      slidesPerView: 1,
    },
    500: {
      slidesPerView: 2,
    },
    600: {
      slidesPerView: 3,
    },

  }}
                 
                
         spaceBetween={30}
         loop={true}
         autoplay={{
           delay: 2500,
           disableOnInteraction: true,
         }}
         pagination={true}
        
         modules={[Autoplay, Pagination, Navigation]}
       
         className="mySwiper"
        
       >
            {
                         data?.map((item, index) => (
         <SwiperSlide>
         <div key={index} className='w-[260px] h-[120px]'>
            <a href={item.url}>
                                 <img className='w-full h-full object-contain' src={item.image || "/logos/logo-full.svg"} alt="logo" />
                                 </a>
                             </div>
         </SwiperSlide>
           ))
         } 

         
         
       </Swiper> 
     



      
                </div>
              
            </div>
        </div>
    )
}

export default Mui