import HTMLReactParser from 'html-react-parser'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { reactLocalStorage } from 'reactjs-localstorage'


const CardSolution = ({
    data,
    image,
    url,
    title,
    info,
    wording,
    mainTrans,
    link,
}) => {
    const navigate = useNavigate()

    const handleClick = () => {
        reactLocalStorage.setObject("rootPath", Object.values(mainTrans)[0].slug)
        navigate(url, {
            state: {
                data: data
            }
        })
    }
    return (
        
        <div className='flex bg-white flex-col md:flex-row items-center border rounded-lg group p-3'>
            <div className="flex flex-col">
                <div className='flex  items-center px-1 pt-3'>
            <img className='block mx-auto' src={image} alt={title}  />
            </div>
            <a  class=" bg-blue-700 hover:bg-blue-500 text-white font-bold py-1 rounded text-center" href={link} target="_blank">
           
           <div className="">
                Download
                </div>
                </a> 
          
            </div>
        </div>
        
    )
}

export default CardSolution