import React from 'react'
import { Link } from 'react-router-dom'
import { reactLocalStorage } from 'reactjs-localstorage'

const FooterLink = ({
    name,
    url = "/"
}) => {
    const lang = reactLocalStorage.getObject("gs_lang").code
    const first = url.substring(0, 5);
    return ( 
        // <Link
        //     to={`/${lang}${url}`}
        //  >
            <a href={
                first == "https" ?
                `${url}`
                :
                `/${lang}${url}`
                }
                target={
                    first == "https" ?
                    "_blank"
                    :''
                }
                >
                <span className='text-[#21A1C9] text-[14px] leading-[26px] hover:underline'>
                {name} 
                </span>
                </a>
            
    // </Link>
    )
}

export default FooterLink