import React from 'react'

const TextLoader = ({ count }) => {
    const cel = []
    for (let i = 0; i < count; i++) {
        cel.push(i)
    }
    return (
        <>
            {
                cel.map(item => (
                    <div key={item}>
                        <div className="h-2 my-1 bg-gray-200 animate-pulse rounded-full dark:bg-gray-300 w-32" />
                        <div className="h-1.5 my-1 bg-gray-200 animate-pulse rounded-full dark:bg-gray-300 w-32" />
                    </div>
                ))
            }
        </>
    )
}

export default TextLoader