import axios from 'axios';
import PageLoader from 'components/atoms/Loader/PageLoader';
import CardSolution from 'components/molecules/Cards/CardSolution';
import CardBulletin from 'components/molecules/Cards/CardBulletin';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { setTranslation } from 'redux/global/translationSlice';
import { setBreadcrumb } from 'redux/navigation/breadcrumbSlice';
import { setSidebar } from 'redux/navigation/sidebarSlice';
import useSWR from 'swr';
import Pagination from 'react-bootstrap/Pagination';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom'

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import { SuperSEO } from "react-super-seo";

const fetcher = async (url) => {
    const response = await axios({
        method: 'get',
        url: url
    })
    return response.data
}
const MainPage = ({code}) => {
  const breadcrumb = useSelector(state => state.breadcrumb.value)
    const dispatch = useDispatch()

    const URL = process.env.REACT_APP_API
    const loc = useLocation()
    const path = loc.pathname.split("/")
    const lang = reactLocalStorage.getObject("gs_lang").code
    const wording = useSelector(state => state.wording.value)
    const slug = loc.pathname.split("/").pop()
    const [currentCategory, setCurrentCategory] = useState(path[2])
    const [archData, setArchData] = useState()
    // const [currentSlug, setCurrentSlug] = useState(slug[3])
    //console.log("code", code)

   
    //console.log("archData", archData)
    const navigate = useNavigate()
    const { data, isLoading } = useSWR(
        `${URL}/${lang}/category/${slug}` , fetcher)
     

    const translation = data?.category?.translation

    let active = 2;
    let items = [];
    for (let number = 1; number <= 5; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active}>
          {number}
        </Pagination.Item>,
      );
    }

    const handleLink = (a) =>{
      //console.log("name", a)

      navigate(`/${lang}/${a}`);
      
         window.location.reload();
    }


    useEffect(() => {
      
      document.title = isLoading? "GS1 Indonesia" : data?.category.name;
        dispatch(setTranslation(translation && Object.values(translation)[0]))
        dispatch(setSidebar(data?.navigation?.sidebar || []))
        dispatch(setBreadcrumb(data?.navigation?.breadcrumb || []))
    }, [data])

    return (
       <>
        <SuperSEO
        description={data?.category.description}
        />
        <div className='pb-5 category'>
          <Breadcrumbs data={breadcrumb} />
          <div className=''>
          <Tab.Container id="left-tabs-example" 
          defaultActiveKey={
            currentCategory =="news" || currentCategory =="berita" ? 0 : 1
          
            }> 
      { isLoading &&
            <PageLoader type="article" /> }
            <Row className='tab-category mx-[0.1px]'>
              <Nav variant="pills" className="flex-row"> { data?.navigation?.tab?.map((item,i) => ( 
              <Nav.Item
              onClick={() => handleLink(item.slug)}
              >
                  <Nav.Link eventKey={i}> 
                  {item.name} 
                  </Nav.Link>
                </Nav.Item> )) } 
                </Nav>
            </Row>
            <Row className='mx-[10px] p-3  border'>
              <Tab.Content> 
                {/* { data?.list?.map((item,i) => ( */} 
                <Tab.Pane eventKey={0}> 
              { data?.list?.map((item, i) => (
                
                <>
               
                 {i==0?
                <div className="row mb-5 border pb-3 items-center">
                <div className="col col-5">
                    <img src={item.image} alt=".."/>
                </div>
                <div className="col px-2">
                    <div className="d-flex row ">
                        <span className='text-sm '>{item.publish}</span>
                    <strong className='text-[#555]'>{item.name}</strong>
                    {
                        item.overview ?
                        <div  dangerouslySetInnerHTML={{ __html: item.overview}}/>
                        :""
                    }
                    
                   
                    </div>
                    <Link to={`/${lang}/${item.slug}`}>
                    <div className="flex row text-[#004c95] justify-end pr-5 mt-3">
                    see more  &#8250;
                </div>
                </Link>
                </div>
               
              </div>
                :  
                
              <div className="row mb-5 border-b pb-3">
                <div className="col col-3">
                    <img src={item.image} alt=".."  className='img-cat-list'/>
                </div>
                <div className="col ">
                    <div className="d-flex row ">
                        <span className='text-sm text-[#9e9e9e]'>{item.publish}</span>
                    <strong className='text-[#555]'>{item.name}</strong>
                    {
                        item.overview ?
                        <div  dangerouslySetInnerHTML={{ __html: item.overview}}/>
                        :""
                    }
                   
                    </div>
                
                   
                </div>
               
                <div className="col col-auto text-[#004c95] items-center">
                <Link to={`/${lang}/${item.slug}`}>
                    see more  &#8250;
                    </Link>
                </div>
                
              </div>
             } 
             
             </>
              )) }
              </Tab.Pane>

              <Tab.Pane eventKey={1}> 
              { data?.list?.map((item,i) => (
                
                <>
               
                {i==0?
                <div className="row mb-5 border pb-3 items-center">
                <div className="col col-5">
                    <img src={item.image} alt=".." />
                </div>
                <div className="col px-2">
                    <div className="d-flex row ">
                        <span className='text-sm '>{item.publish}</span>
                    <strong className='text-[#555]'>{item.name}</strong>
                    {
                        item.overview ?
                        <div  dangerouslySetInnerHTML={{ __html: item.overview}}/>
                        :""
                    }
                    
                   
                    </div>
                    <Link to={`/${lang}/${item.slug}`}>
                    <div className="flex row text-[#004c95] justify-end pr-5 mt-3">
                    see more  &#8250;
                </div>
                </Link>
                </div>
               
              </div>
                : 
                
              <div className="row mb-5 border-b pb-3">
                <div className="col col-3">
                    <img src={item.image} alt=".." className='img-cat-list' />
                </div>
                <div className="col ">
                    <div className="d-flex row ">
                        <span className='text-sm text-[#9e9e9e]'>{item.publish}</span>
                    <strong className='text-[#555]'>{item.name}</strong>
                    {
                        item.overview ?
                        <div  dangerouslySetInnerHTML={{ __html: item.overview}}/>
                        :""
                    }
                   
                    </div>
                
                   
                </div>
            
                <div className="col col-auto text-[#004c95] items-center">
                <Link to={`/${lang}/${item.slug}`}>
                    see more  &#8250;
                    </Link>
                </div>
               
              </div>
              } 
             
             </>
              )) }
              </Tab.Pane>
              
                {/* <Tab.Pane eventKey={1}> Data no Found </Tab.Pane>  */}
                {/* )) } */}
              </Tab.Content>
            </Row>
          </Tab.Container>
        </div>
        <div>
          <div className=' items-center border-r border-l border-b border-t-4 border-t-[#ee7442] group p-3 mt-5'>
            <div className="row py-2 px-4 border-b text-[#004c95] "> Archive </div>
            <div className="row px-4 pt-3">
              <div className="d-flex col gap-3"> { data?.archive?.map((arc,i) => (
                <Link to={`/${lang}/${slug}/${arc.year}`}>
                <div className="d-flex col gap-3 text-info">
                  <div>{arc.year}</div>
                  <div>&#8250;</div>
                </div>
                </Link> ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    
      </>

    )
}

export default MainPage