import { useRef, useEffect, useState } from "react";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Navigation, Autoplay, Parallax } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import HTMLReactParser from "html-react-parser";
const SectionBanner = ({ error, data, wording }) => {
  const [swiper, setSwiper] = useState(null);
  const [showBuutton, setshowBuutton] = useState(false);
  // const [activeIndex, setActiveIndex] = useState(1);
  // //console.log("activeIndex", activeIndex)
  const SwiperButtonNext = () => {
    const swiper = useSwiper();
    //   //console.log("current", swiper.activeIndex)
    return (
      <button
        // ref={navigationNextRef}
        onClick={() => swiper.slideNext()}
        className="bg-white cursor-pointer hover:scale-110 duration-300 h-12 w-12 flex items-center justify-center rounded-full shadow"
      >
        <FaArrowRight className="text-body-primary" />
      </button>
    );
  };

  const prevto = () => {
    if (swiper.activeIndex - 1 < 0) {
      swiper.slideTo(swiper.slides.length - 1);
    } else {
      swiper.slideTo(swiper.activeIndex - 1);
    }
  };
  const nexto = () => {
    if (swiper.activeIndex + 1 > swiper.slides.length - 1) {
      swiper.slideTo(0);
    } else {
      swiper.slideTo(swiper.activeIndex + 1);
    }
  };
  return (
    <>
      <div>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          //  activeIndex={0}
          autoplay={{
            delay: 20000,
            disableOnInteraction: false,
          }}
          className="h-[65vh]"
          modules={[Navigation, Autoplay]}
          onSwiper={(s) => {
            //console.log("initialize swiper", s);
            setSwiper(s);
          }}
        >
          {data?.banner?.map((item) => (
            <SwiperSlide key={item.name} className="relative">
              <div
                className={`layer  absolute left-0 right-0 bottom-0 top-0`}
                style={{
                  backgroundImage: `linear-gradient(to right, ${item.background} 50%, transparent)`,
                }}
              >
                <div className="flex row gap-5">
                  <div className="page-container h-full flex flex-col justify-center items-start gap-3 z-100 mt-5">
                    <h1
                      className={`text-[20px] md:text-[32px] w-full md:w-6/12 ${
                        item.background === "#ffffff"
                          ? "text-body-primary"
                          : "text-white"
                      }  text-shadow-dark leading-[30px] lg:leading-[42px] desc-gallery`}
                    >
                      {/* {item.name} */}
                      {HTMLReactParser(item.name)}
                    </h1>
                    {item.description == false ? (
                      ""
                    ) : (
                      <div
                        className={`z-80 text-[16px] md:text-[18px] w-full md:w-1/2 text-white leading-[22px]  lg:leading-[30px] desc-gallery pb-5`}
                      >
                        {HTMLReactParser(item.description)}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <img
                src={item.responsive.small}
                alt="hero"
                className="md:hidden w-full h-full object-contain object-right"
              />
              <img
                src={item.responsive.medium}
                alt="hero"
                className="hidden md:block lg:hidden w-full h-full object-contain object-right"
              />
              <img
                src={item.responsive.large}
                alt="hero"
                className="hidden lg:block w-full h-full object-contain object-right"
              />
            </SwiperSlide>
          ))}
          {/* <button onClick={prevto}>prev</button>
      <button onClick={nexto}>next</button> */}
          {data ? (
            <div className="relative z-[80] gap-6 left-0 right-0 bottom-20 flex justify-center page-container w-auto ">
              <button
                // ref={navigationPrevRef}
                //onClick={swiperref.current.slidePrev()}
                onClick={prevto}
                className="bg-white cursor-pointer hover:scale-110 duration-300 h-12 w-12 flex items-center justify-center rounded-full shadow"
              >
                <FaArrowLeft className="text-body-primary" />
              </button>
              <button
                // ref={navigationNextRef}
                onClick={nexto}
                className="bg-white cursor-pointer hover:scale-110 duration-300 h-12 w-12 flex items-center justify-center rounded-full shadow"
              >
                <FaArrowRight className="text-body-primary" />
              </button>
              {/* <SwiperButtonNext></SwiperButtonNext> */}
            </div>
          ) : (
            ""
          )}
        </Swiper>
      </div>
    </>
  );
};

export default SectionBanner;
