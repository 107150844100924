import axios from 'axios';
import PageLoader from 'components/atoms/Loader/PageLoader';
import CardSolution from 'components/molecules/Cards/CardSolution';
import CardBulletin from 'components/molecules/Cards/CardBulletin';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { setTranslation } from 'redux/global/translationSlice';
import { setBreadcrumb } from 'redux/navigation/breadcrumbSlice';
import { setSidebar } from 'redux/navigation/sidebarSlice';
import useSWR from 'swr';
import Pagination from '@mui/material/Pagination';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import { SuperSEO } from "react-super-seo";

const fetcher = async (url) => {
    const response = await axios({
        method: 'get',
        url: url
    })
    return response.data
}
const MainPage = ({ code }) => {
    const dispatch = useDispatch()
    const URL = process.env.REACT_APP_API
    const breadcrumb = useSelector(state => state.breadcrumb.value)
    const loc = useLocation()
    const [page, setPage] = useState()
    const [list, setList] = useState([])
    const [pageCount, setPageCount] = useState()
    const lang = reactLocalStorage.getObject("gs_lang").code
    const wording = useSelector(state => state.wording.value)
    const slug = loc.pathname.split("/").pop()
    //console.log("code", code)
    const { data, isLoading } = useSWR(
        `${URL}/${lang}/bulletin/${slug}/page/1` , fetcher)


    const translation = data?.category?.translation

    const FetchList = async () => {
       
        axios.get( `${URL}/${lang}/bulletin/${slug}/page/1`,
        ).then(res => {
     
        const dataList = res.data.list
        setList(dataList)
       
        let pagecount = Math.ceil(res.data.pagination.total / res.data.pagination.limit)
        setPageCount(pagecount)
        return res;
        })
    }

    const handlePage = async (val) => {
        axios.get( `${URL}/${lang}/bulletin/${slug}/page/${val}`,
        ).then(res => {
     
        const dataList = res.data.list
        setList(dataList)
       
        let pagecount = Math.ceil(res.data.pagination.total / res.data.pagination.limit)
        setPageCount(pagecount)
        return res;
        })
    }

    //console.log("list", list)


    useEffect(() => {
        FetchList();
        //  document.title = data?.category?.name;
        dispatch(setTranslation(translation && Object.values(translation)[0]))
        dispatch(setSidebar(data?.navigation?.sidebar || []))
        dispatch(setBreadcrumb(data?.navigation?.breadcrumb || []))
    }, [data])

    return (
        <>
       <SuperSEO
        description={data?.category?.description}
        />
        <div className='mb-5 Bulletin'>
            <Breadcrumbs data={breadcrumb} />
        <div className='mb-12'>
            <h1 className='text-body-primary text-2xl mb-5'>
                {data?.category?.name}
            </h1>
            <article>
                {parse(data?.category?.description || "")}
            </article>
        </div>
        <div>

            <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {
                    isLoading && <PageLoader type="article" />
                }
                {
                    list?.map(item => ( 
                        <CardBulletin
                            navigation={data?.navigation}
                            mainTrans={data?.category?.translation}
                            data={item}
                            wording={wording}
                            key={item.id}
                            image={item.image}
                            url={item.slug}
                            link={item.document}
                            title={item.name}
                            info={item.description}
                        />
                    ))
                }
            </div>
            <div className="flex flex-row justify-center mt-5">
            <Pagination 
                            count={pageCount} 
                            shape="rounded" 
                          
                            onChange={(e, value) => handlePage(value) }
                            /> 
            </div>

        </div>
    </div>
    </>
    )
}

export default MainPage