import parse from 'html-react-parser';
const Dedication = ({ data }) => {
    return (
        
        <div className="grid items-center md:grid-cols-2 gap-5  ">
            <div className=' my-[80px] lg:my-[133px]  dedication-col'>
                <article>
                    <h1 className=' text-[30px] lg:text-[40px]  leading-[72px]'>{data?.content?.name}</h1>
                  
                </article>
                <div className='flex flex-col gap-3 pr-5 lg:pr-30  mr-14 mt-3 text-[18px]'>
                {
                        data && parse(data?.content?.description)
                    }
                </div>
            </div>
            <div className='flex items-center h-full relative '>
                <div className="layer bg-blue-light absolute left-0 lg:left-10 z-10 right-0 bottom-0 top-0"></div>
                <div className="flex-1 z-20 px-6 lg:px-0">
                    {
                        data?.list?.map(item => {
                            if (!item.unpublish) {
                                return (
                                    <div
                                        key={item.id}
                                        // className='flex gap-4 my-4 md:my-8 lg:my-12'
                                        className='flex gap-4 my-4 md:my-8 lg:my-[133px] mr-[30px] lg:mr-[95px]'
                                        >
                                        <div className='w-12 lg:w-20'>
                                            <img src={item.image || "/images/ded-1.png"} alt="" />
                                        </div>
                                        <div className='p-3 text-sm lg:text-base flex-1 bg-white text-body-primary mr-0 lg:mr-24'>
                                            {item.name}
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
           
        </div>
        
    )
}

export default Dedication