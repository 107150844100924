import classNames from 'classnames'

const Button = ({
    varian = "orange",
    size = "md",
    label = "Button Text",
    outline,
    shadow,
    icon,
    center,
    ...rest
}) => {

    const ClassButton = classNames({
        "rounded-md flex gap-3 items-center group": true,
        "shadow-md shadow-slate-400": shadow,
        "bg-base-orange ": varian === "orange" && !outline,
        "border border-[#FC7A31] text-[#FC7A31]": varian === "orange" && outline,
        "py-2.5 px-5 font-semibold": size === "md",
        "py-2 px-4 font-normal": size === "sm",
        "py-2 px-2 font-normal text-sm": size === "xs",
    })


    return (
        <button {...rest} className={ClassButton}>
            <span className='duration-300'>{label}</span>
            {icon}
        </button>
    )
}

export default Button