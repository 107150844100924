import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    value: [],
}


export const breadcrumbSlice = createSlice({
    name: 'breadcrumb',
    initialState,
    reducers: {
        setBreadcrumb: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setBreadcrumb } = breadcrumbSlice.actions

export default breadcrumbSlice.reducer