import React, { Fragment, useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { reactLocalStorage } from 'reactjs-localstorage'
const SearchInput = ({
    placeholder,
    name,
    ...rest
}) => {
    const [isi, setIsi] = useState()
    const navigate = useNavigate()
    const lang = reactLocalStorage.getObject("gs_lang").code
    const handleSearch = (ss) =>{
        localStorage.setItem("SearchData", ss)
        navigate(`/${lang}/search`)
        window.location.reload();
    }
    return (
        <div className='p-2.5 items-center focus-within:border-[#2A5985] duration-300 gap-3 bg-white rounded-md border flex'>
            <BsSearch color='#F26921' />
            <input
                {...rest}
                name={name}
                className='flex-1 bg-transparent outline-none'
                type="text" placeholder={placeholder}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        handleSearch(e.target.value)
                    }
                }}
            />
        </div>
    )
}

export default SearchInput