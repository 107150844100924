import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser'
import { reactLocalStorage } from 'reactjs-localstorage'

const Registration = ({
    data,
    wording
}) => {
    const navigate = useNavigate()
    const lang = reactLocalStorage.getObject("gs_lang")
    const langNew = lang.code
    return (
        <div className='relative'>
            <div className="h-[355.26px] p-0">
                <img
                    className='object-contain object-right h-full w-full'
                    src={data?.content?.image}
                    alt={data?.content?.slug} />
            </div>
            <div className='absolute top-0 bottom-0 left-0 right-0'
                style={{
                    backgroundImage: `linear-gradient(to right, #F26334 60%, transparent)`
                }}
            >
                <div className="page-container gap-6 lg:gap-10 flex flex-col justify-center items-start h-full w-full">
                    <h1 className={`text-[30px] lg:text-[32px] w-full md:w-1/2 text-white font-semibold text-shadow-dark`}>
                        {data?.content?.name}
                    </h1>
                    <div className="text-white text-sm lg:text-[18px] w-full md:w-1/2">
                       {
                            data && parse(data?.content?.description)
                        } 
                        {/* {HTMLReactParser(data?.content?.description)} */}
                    </div>
                    {/* <p>
                 <a href={`/${langNew}/${data?.content?.slug}`}>
               
                     <button
                        
                        className="bg-base-primary shadow-xl group p-3 rounded-md font-semibold text-sm hover:scale-105 duration-300">
                        <span >
                            {wording?.event_more?.toUpperCase()}
                        </span>
                    </button> 
                    
                 </a> 
                 </p> */}
                </div>
            </div>
        </div>
    )
}

export default Registration