import { configureStore } from '@reduxjs/toolkit'
import menuFooterReducer from "./global/menuFooterSlice"
import menuHeaderReducer from "./global/menuHeaderSlice"
import wordingReducer from "./global/wordingSlice"
import menuTopReducer from "./global/menuTopSlice"
import mobileMenuReducer from "./global/mobileMenu"
import homeReducer from "./home/homeSlice"
import languageReducer from "./language/languageSlice"
import mappingReducer from "./global/mappingSlice"
import translationReducer from "./global/translationSlice"
import sidebarReducer from "./navigation/sidebarSlice"
import breadcrumbReducer from "./navigation/breadcrumbSlice"
export const store = configureStore({
    reducer: {
        language: languageReducer,
        wording: wordingReducer,
        menutop: menuTopReducer,
        menufooter: menuFooterReducer,
        menuheader: menuHeaderReducer,
        mobilemenu: mobileMenuReducer,
        home: homeReducer,
        mapping: mappingReducer,
        translation: translationReducer,
        sidebar: sidebarReducer,
        breadcrumb: breadcrumbReducer,
    },
})