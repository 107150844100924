import axios from 'axios';
import PageLoader from 'components/atoms/Loader/PageLoader';
import CardSolution from 'components/molecules/Cards/CardSolution';
import CardBulletin from 'components/molecules/Cards/CardBulletin';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { setTranslation } from 'redux/global/translationSlice';
import { setBreadcrumb } from 'redux/navigation/breadcrumbSlice';
import { setSidebar } from 'redux/navigation/sidebarSlice';
import useSWR from 'swr';
import Pagination from '@mui/material/Pagination';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom'

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import HTMLReactParser from 'html-react-parser'

const fetcher = async (url) => {
    const response = await axios({
        method: 'get',
        url: url
    })
    return response.data
}
const MainPage = ({code}) => {
  const breadcrumb = useSelector(state => state.breadcrumb.value)
    const dispatch = useDispatch()
    const URL = process.env.REACT_APP_API
    const loc = useLocation()
    const [list, setList] = useState([])
    const [pageCount, setPageCount] = useState()
    const lang = reactLocalStorage.getObject("gs_lang").code
    const dataSearch = localStorage.getItem("SearchData")
    const wording = useSelector(state => state.wording.value)
    const slug = loc.pathname.split("/").pop()
    const path = loc.pathname.split("/")
    // const [currentSlug, setCurrentSlug] = useState(slug[3])
    //console.log("code", code)
    const { data, isLoading } = useSWR(
        `${URL}/${lang}/search/${dataSearch}` , fetcher)


        const FetchList = async () => {
       
          axios.get( `${URL}/${lang}/search/${dataSearch}`,
          ).then(res => {
       
          const dataList = res.data.list
          setList(dataList)
         
          let pagecount = Math.ceil(res.data.pagination.total / res.data.pagination.limit)
          setPageCount(pagecount)
          return res;
          })
      }

      const handlePage = async (val) => {
        axios.get( `${URL}/${lang}/search/${dataSearch}/page/${val}`,
        ).then(res => {
     
        const dataList = res.data.list
        setList(dataList)
       
        let pagecount = Math.ceil(res.data.pagination.total / res.data.pagination.limit)
        setPageCount(pagecount)
        return res;
        })
    }

    //console.log("list", list)
     

    const translation = data?.category?.translation
    useEffect(() => {
      FetchList();
        dispatch(setTranslation(translation && Object.values(translation)[0]))
        dispatch(setSidebar(data?.navigation?.sidebar || []))
        dispatch(setBreadcrumb(data?.navigation?.breadcrumb || []))
    }, [data])

    return (
       
        <div className='pb-5 category'>
          <Breadcrumbs data={breadcrumb} />
      <div className='mt-5 border border-2 rounded-md p-3'>
        <div className='text-[#002c6c] text-2xl'>Search result for: {dataSearch}</div>
        <div className='mb-4 text-[16px] '>Total found: {data?.pagination?.total}</div>

        {
                    list?.map(item => (
                      <div className='flex row gap-3 border-b-2  mb-3 pb-3'>
                         <Link to={`/${lang}/${item.slug}`}>
                        <div className="flex row gap-1">
                    <div className='text-info text-xl'>{item.name}</div>
                    <span className='text-[0.75rem] text-[#000000] opacity-50'>{item.publish}</span>
                    </div>
                      <div className='text-base text-[#333]'>
                        {HTMLReactParser(item.description || "")}
                      </div>
                      </Link>
                    </div>
                    ))}
      </div>
      <div className="flex flex-row justify-center mt-5">

      <Pagination 
                            count={pageCount} 
                            shape="rounded" 
                          
                             onChange={(e, value) => handlePage(value) }
                            /> 
                             </div>
       
      </div>
    
   

    )
}

export default MainPage