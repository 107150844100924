import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { reactLocalStorage } from "reactjs-localstorage"
const lang = reactLocalStorage.getObject("gs_lang").code || "en"
export const getMenuHeader = createAsyncThunk("menutop/getMenuHeader", async () => {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}/${lang}/menu/header`
    })
    return response.data
})

const menuheaderEntity = createEntityAdapter({
    selectId: (menuheader) => menuheader.id
})

const menuheaderSlice = createSlice({
    name: "menuheader",
    initialState: menuheaderEntity.getInitialState(),
    extraReducers: {
        [getMenuHeader.pending]: (state, action) => {
            state.isLoading = true
        },
        [getMenuHeader.fulfilled]: (state, action) => {
            menuheaderEntity.setAll(state, action.payload)
            state.isLoading = false
        },
    }
})

export const menuHeaderSelectors = menuheaderEntity.getSelectors(state => state.menuheader)

export default menuheaderSlice.reducer