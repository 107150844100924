import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMenuFooter } from 'redux/global/menuFooterSlice'
import FooterBlock from './FooterBlock'
import useSWR from 'swr'
import axios from "axios"
import { Link } from 'react-router-dom'
import { setWording } from "redux/global/wordingSlice";
import { reactLocalStorage } from "reactjs-localstorage";

const fetcher = async (url) => {
    const response = await axios({
        method: 'get',
        url: url
    })
    return response.data
}

const Footer = () => {
    const dispatch = useDispatch()
    const { entities } = useSelector(state => state.menufooter)
    const data = Object.values(entities)
    const langTrans = localStorage.getItem("rootPath")
    const langObj = reactLocalStorage.getObject("gs_lang")
  !langObj.code && reactLocalStorage.setObject("gs_lang", {
    name: 'English',
    code: 'en',
  })
    const lang = langObj.code ? langObj.code : "en"
    const { data: wording } = useSWR(`${process.env.REACT_APP_API}/wording`, fetcher);
    const { data:dataBottom } = useSWR(`${process.env.REACT_APP_API}/${langTrans}/menu/bottom`, fetcher);
    const { data:dataSetting } = useSWR(`${process.env.REACT_APP_API}/setting`, fetcher);
    // const [data] = useState([
    //     {
    //         "id": 34,
    //         "parent": false,
    //         "name": "About Us",
    //         "info": false,
    //         "target": false,
    //         "url": "javascript:void(0);",
    //         "slug": "javascript:void(0);",
    //         "children": [
    //             {
    //                 "id": 35,
    //                 "parent": 34,
    //                 "name": "GS1",
    //                 "info": false,
    //                 "target": false,
    //                 "url": "/gs1",
    //                 "slug": "/gs1"
    //             },
    //             {
    //                 "id": 36,
    //                 "parent": 34,
    //                 "name": "GS1 Indonesia",
    //                 "info": false,
    //                 "target": false,
    //                 "url": "/gs1-indonesia",
    //                 "slug": "/gs1-indonesia"
    //             },
    //             {
    //                 "id": 37,
    //                 "parent": 34,
    //                 "name": "History",
    //                 "info": false,
    //                 "target": false,
    //                 "url": "/gs1-indonesia-history",
    //                 "slug": "/gs1-indonesia-history"
    //             }
    //         ]
    //     },
    //     {
    //         "id": 38,
    //         "parent": false,
    //         "name": "Get Barcode",
    //         "info": false,
    //         "target": false,
    //         "url": "javascript:void(0);",
    //         "slug": "javascript:void(0);",
    //         "children": [
    //             {
    //                 "id": 42,
    //                 "parent": 38,
    //                 "name": "List of GS1 Indonesia Member",
    //                 "info": false,
    //                 "target": false,
    //                 "url": "/daftar-anggota-gs1-indonesia-2-1-1",
    //                 "slug": "/daftar-anggota-gs1-indonesia-2-1-1"
    //             },
    //             {
    //                 "id": 39,
    //                 "parent": 38,
    //                 "name": "Requirements & Flow",
    //                 "info": false,
    //                 "target": false,
    //                 "url": "/requirements-flow",
    //                 "slug": "/requirements-flow"
    //             },
    //             {
    //                 "id": 41,
    //                 "parent": 38,
    //                 "name": "Printed Registration Form",
    //                 "info": false,
    //                 "target": false,
    //                 "url": "/gs1-indonesia-membership-registration-form",
    //                 "slug": "/gs1-indonesia-membership-registration-form"
    //             },
    //             {
    //                 "id": 40,
    //                 "parent": 38,
    //                 "name": "10 Step of GS1 System Implementation",
    //                 "info": false,
    //                 "target": false,
    //                 "url": "/ten-steps-to-implement-gs1-barcode",
    //                 "slug": "/ten-steps-to-implement-gs1-barcode"
    //             }
    //         ]
    //     },
    //     {
    //         "id": 43,
    //         "parent": false,
    //         "name": "Our Facility",
    //         "info": false,
    //         "target": false,
    //         "url": "javascript:void(0);",
    //         "slug": "javascript:void(0);",
    //         "children": [
    //             {
    //                 "id": 44,
    //                 "parent": 43,
    //                 "name": "Online Product Registration",
    //                 "info": false,
    //                 "target": false,
    //                 "url": "/registrasi-produk",
    //                 "slug": "/registrasi-produk"
    //             },
    //             {
    //                 "id": 45,
    //                 "parent": 43,
    //                 "name": "GS1 System Training",
    //                 "info": false,
    //                 "target": false,
    //                 "url": "/event",
    //                 "slug": "/event"
    //             },
    //             {
    //                 "id": 46,
    //                 "parent": 43,
    //                 "name": "Barcode Verification",
    //                 "info": false,
    //                 "target": false,
    //                 "url": "/verifikasi-barcode",
    //                 "slug": "/verifikasi-barcode"
    //             },
    //             {
    //                 "id": 47,
    //                 "parent": 43,
    //                 "name": "Product Photo Service",
    //                 "info": false,
    //                 "target": false,
    //                 "url": "/foto-produk-untuk-member-gs1-indonesia",
    //                 "slug": "/foto-produk-untuk-member-gs1-indonesia"
    //             }
    //         ]
    //     }
    // ])
    useEffect(() => {
        dispatch(getMenuFooter())
        dispatch(setWording(wording && wording[lang]))
    }, [dispatch, dataBottom, dataSetting, wording])

    const wordingNew = wording && wording[lang]

    return (
        <div
            className='py-6 md:py-12 lg:pt-24'
            style={{
                background: `#FFFFFF`
            }}
        >
            <div className="page-container">
                <div className="col-footer gap-4">
                <div className='mb-6 md:mb-10 lg:mb-20'>
                    <div className="grid grid-cols-2 lg:grid-cols-6 gap-1 lg:gap-2">
                        {
                            data && data?.map(item => (
                                <FooterBlock
                                    key={item.id}
                                    data={item}
                                />
                            ))
                        }
                    </div>
                </div>
                <div className="flex-1 mb-5 lg-mb-0 col-auto">
                    
                        <div className='flex gap-3 mt-6'>
                            <a
                                href={dataSetting?dataSetting.social_twitter :""}
                                target="_blank"
                                rel="noopener noreferrer"
                                className='h-12 w-12 flex items-center justify-center  rounded-md'
                            >
                                <img src="/images/sosmed/gs1-symbol-twitter.svg" alt="twitter" />
                            </a>
                            <a
                                href={dataSetting?dataSetting.social_linkedin :""}
                                target="_blank"
                                rel="noopener noreferrer"
                                className='h-12 w-12 flex items-center justify-center  rounded-md'
                            >
                                <img src="/images/sosmed/gs1-symbol-linkedin.svg" alt="instagram" />
                            </a>
                       
                            <a
                                href={dataSetting?dataSetting.social_youtube :""}
                                target="_blank"
                                rel="noopener noreferrer"
                                className='h-12 w-12 flex items-center justify-center  rounded-md'
                            >
                                <img src="/images/sosmed/gs1-symbol-youtube.svg" alt="youtube" />
                            </a>

                            <a
                                href={dataSetting?dataSetting.social_instagram :""}
                                target="_blank"
                                rel="noopener noreferrer"
                                className='h-12 w-12 flex items-center justify-center  rounded-md'
                            >
                                <img src="/images/sosmed/gs1-symbol-instagram.svg" alt="youtube" />
                            </a>
                        </div>
                    </div>
                </div>
                 <div className="text-[#888888] text-lg">
                {wordingNew?.description_apps_footer}
                </div> 
                <div className="flex col gap-2 mb-6 md:mb-9 lg:mb-18 mt-3">
                <a href={dataSetting?dataSetting.app_apple :""}>
                    <img src="/images/download/app-store.svg" alt=".." className='download-app'/>
                    </a>
                    <a href={dataSetting?dataSetting.app_google:""}>
                    <img src="/images/download/google-play.svg" alt=".." className='download-app'/>
                    </a>
                </div>
                
                <div className='md:flex border-t border-black'>
                    <div className="flex row gap-3">
                 <div className="flex col gap-2 mt-3 text-[#21A1C9]  text-sm">
                 { dataBottom?.map((item,i) => (
                    <>
                      <a
                        href={item.url}
                        target="_blank">
                        <div>{item.name}</div>
                                </a>
      <div className='border-l'></div>
      </>
    ))}
                  
                    
                 </div>
                 <div className='text-[#888888] text-sm'>GS1 is a registered trademark of GS1 AISBL. All contents copyright &#169; GS1 AISBL 2016.</div>
                 </div>
                </div>
            </div>
        </div>
    )
}

export default Footer