import HTMLReactParser from 'html-react-parser'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { reactLocalStorage } from 'reactjs-localstorage'

const CardSolution = ({
    data,
    image,
    url,
    title,
    info,
    wording,
    mainTrans
}) => {
    const navigate = useNavigate()

    const handleClick = () => {
        reactLocalStorage.setObject("rootPath", Object.values(mainTrans)[0].slug)
        navigate(url, {
            state: {
                data: data
            }
        })
        window.location.reload();
    }
    return (
        <div className='flex bg-white flex-col md:flex-row items-center border rounded-lg group'>
            <div className="w-full md:border-r h-full p-4 flex items-center md:w-1/3 lg:w-1/4">
                <img className='block mx-auto' src={image} alt={title} />
            </div>
            <div className="flex-1 p-4">
                <div className='flex flex-col gap-5 items-start'>
                    <h3 className="text-body-primary text-xl">{title}</h3>
                    <article>
                        {HTMLReactParser(info) || ""}
                    </article>
                    <button
                        onClick={handleClick}
                        className='bg-base-orange group-hover:scale-105 duration-300 py-2 px-6 rounded-md'>
                        {wording?.btn_more || ""}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CardSolution